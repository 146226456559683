export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryTransparent = 'secondary-transparent',
  Highlighted = 'highlighted',
  HighlightedAlt = 'highlighted-alt',
  HighlightedAltTransparent = 'highlighted-alt-transparent',
  HighlightedTransparent = 'highlighted-transparent',
  Caution = 'caution',
  CautionTransparent = 'caution-transparent',
  Underlined = 'underlined'
}

export enum ButtonSize {
  Regular = 'regular',
  Small = 'small'
}
