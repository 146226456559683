import React from 'react';
import cn from 'classnames';

import { StatusBadgeTheme } from './StatusBadge.enum';

import styles from './StatusBadge.module.scss';

interface Props {
  theme?: StatusBadgeTheme;
  title: string;
  className?: string;
}

export const StatusBadge: React.FC<Props> = ({
  theme = StatusBadgeTheme.Red,
  title,
  className
}) => <div className={cn(styles.badge, styles[theme], className)}>{title}</div>;
