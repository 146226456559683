import React, { ChangeEvent, FocusEvent } from 'react';
import cn from 'classnames';

import { InputUnitConfig } from './InputUnitConfig';

import styles from './FormUnitInput.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  inputClassName?: string;
  title: string;
  unitConfig: InputUnitConfig;
}

export const FormUnitInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      unitConfig,
      inputClassName,
      onChange,
      onBlur,
      title,
      readOnly,
      value,
      ...inputProps
    },
    ref
  ) => {
    const renderTitle = (inputTitle: string) => (
      <span
        className={cn(
          styles['unit-container'],
          readOnly && styles['read-only']
        )}
      >
        {inputTitle}
      </span>
    );

    if (readOnly) {
      const inputValue = value ? `${value} ${title}` : '-';

      return renderTitle(inputValue);
    }

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value = '' } = event.target;
      const isValid = [
        unitConfig.isValidLength(value),
        unitConfig.isValidChar(value)
      ].every(Boolean);

      if (isValid) {
        onChange && onChange(event);
      }
    };

    const roundValue = (event: FocusEvent<HTMLInputElement>, value: number) => {
      const input = event.target;
      const changeEvent = new Event('change', { bubbles: true });
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;

      nativeInputValueSetter &&
        nativeInputValueSetter.call(
          input,
          unitConfig.getRoundedValue(Number(value))
        );

      input.dispatchEvent(changeEvent);
    };

    const onInputBlur = (event: FocusEvent<HTMLInputElement>) => {
      const { value = '' } = event.target;
      const { min = 0, max = 0 } = unitConfig.value;

      if (!min && !max) {
        onBlur && onBlur(event);

        return;
      }

      const isValid = value && Number(value) >= min && Number(value) <= max;

      if (value && !isValid) {
        roundValue(event, Number(value));
      }

      onBlur && onBlur(event);
    };

    return (
      <div className={cn(styles['form-unit-input'], className)}>
        <input
          {...inputProps}
          value={value}
          onBlur={onInputBlur}
          onChange={onInputChange}
          ref={ref as React.Ref<HTMLInputElement>}
          className={cn(styles.input, inputClassName)}
        />
        {renderTitle(title)}
      </div>
    );
  }
);
