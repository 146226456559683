import React from 'react';
import cn from 'classnames';

import { KeyValueRowDirection, KeyValueRowSize } from './KeyValueRow.enum';

import styles from './KeyValueRow.module.scss';

interface Props {
  title: string | number;
  value: string | number;
  className?: string;
  titleClassName?: string;
  size?: KeyValueRowSize;
  direction?: KeyValueRowDirection;
  reverse?: boolean;
}

export const KeyValueRow: React.FC<Props> = ({
  size = KeyValueRowSize.Medium,
  reverse = false,
  direction = KeyValueRowDirection.Horizontal,
  title,
  value,
  titleClassName,
  className
}) => (
  <div
    className={cn(
      styles['key-value-row'],
      styles[size],
      styles[direction],
      reverse && styles.reverse,
      className
    )}
  >
    <span className={cn(styles.title, titleClassName)}>{title}</span>
    <span className={styles.value}>{value}</span>
  </div>
);
