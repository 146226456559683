import React, { FC } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import cn from 'classnames';

import { TooltipIds, TooltipPosition, TooltipTheme } from './Tooltip.enum';

import styles from './Tooltip.module.scss';

interface Props extends TooltipProps {
  tooltipId?: string;
  theme?: TooltipTheme;
  isTransparent?: boolean;
}

export const TooltipManager: FC<Props> = ({
  theme = TooltipTheme.Primary,
  tooltipId = TooltipIds.DefaultPrimary,
  effect = 'solid',
  delayShow = 500,
  place = TooltipPosition.Bottom,
  isTransparent = false,
  ...rest
}) => {
  const tooltipProps: TooltipProps = {
    ...rest,
    effect,
    place,
    delayShow
  };

  return (
    <div className={cn(styles.tooltip, styles[theme])}>
      <ReactTooltip
        id={tooltipId}
        html
        className={cn(styles.wrap, {
          [styles['non-transparent']]: !isTransparent
        })}
        {...tooltipProps}
      />
    </div>
  );
};
