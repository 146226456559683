import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'components/Icon';
import { useCustomTranslation } from 'hooks/translation';

import styles from './PaginationControls.module.scss';

type ButtonCallback = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

interface Props {
  pageNumber: number | string;
  nextPageDisabled?: boolean;
  prevPageDisabled?: boolean;
  skipBackDisabled?: boolean;
  className?: string;
  onNextPageClick?: ButtonCallback;
  onPrevPageClick?: ButtonCallback;
  onSkipBackClick?: ButtonCallback;
}

export const PaginationControls: React.FC<Props> = ({
  className,
  pageNumber,
  nextPageDisabled,
  prevPageDisabled,
  skipBackDisabled,
  onNextPageClick,
  onPrevPageClick,
  onSkipBackClick
}) => {
  const { t } = useCustomTranslation();

  return (
    <div className={cn(styles['pagination-controls'], className)}>
      <div className={styles['page-controls']}>
        <button
          className={styles['control-button']}
          data-testid='skip-back-button'
          onClick={(e) => onSkipBackClick && onSkipBackClick(e)}
          disabled={skipBackDisabled}
        >
          <Icon iconType={IconType.SkipArrow} />
        </button>
        <span className={styles['page-counter']}>
          {t('Page')} {pageNumber}
        </span>
        <button
          className={cn(styles['control-button'], styles['prev-page'])}
          data-testid='prev-page-button'
          onClick={(e) => onPrevPageClick && onPrevPageClick(e)}
          disabled={prevPageDisabled}
        >
          <Icon iconType={IconType.ChevronDown} />
        </button>
        <button
          className={cn(styles['control-button'], styles['next-page'])}
          data-testid='next-page-button'
          onClick={(e) => onNextPageClick && onNextPageClick(e)}
          disabled={nextPageDisabled}
        >
          <Icon iconType={IconType.ChevronDown} />
        </button>
      </div>
    </div>
  );
};
