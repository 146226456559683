import React from 'react';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

export class BlockFragment extends BaseBlock {
  identifier = BlockIdentifier.BlockFragment;

  Component: React.FC = ({ children }) => {
    return <>{children}</>;
  };
}
