import React from 'react';
import cn from 'classnames';
import { Col as ColComponent } from 'components/Grid/Col';
import { Row as RowComponent } from 'components/Grid/Row';
import { ColSize } from 'components/Grid/types';
import {
  MultiRowDataType,
  useReportDataValue
} from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';
import { Text } from 'components/Text';
import { TextColors, TextThemes } from 'components/Text/Text';

import { BaseBlock } from '../BaseBlock';
import { isEmptyMultiRowData } from './utils';

import styles from './MultiRowProperty.module.scss';

interface MetaData {
  textWrapEnabled?: boolean;
  title?: {
    theme?: TextThemes;
    size?: ColSize;
    color?: TextColors;
    uppercase?: boolean;
  };
  value?: {
    theme?: TextThemes;
    size?: ColSize;
    color?: TextColors;
    padded?: boolean;
  };
  subValue?: {
    theme?: TextThemes;
    color?: TextColors;
    italic?: boolean;
  };
}

const defaultColWidth: ColSize = 6;

export class MultiRowProperty extends BaseBlock<MetaData, MultiRowDataType> {
  identifier = BlockIdentifier.MultiRowProperty;

  Component: React.FC<MetaData> = ({
    title,
    value,
    subValue,
    textWrapEnabled = false
  }) => {
    const data = useReportDataValue(this.accessor);

    const isEmpty = isEmptyMultiRowData(data);

    if (!data || isEmpty) {
      return null;
    }

    const titleSize = title?.size || defaultColWidth;
    const titleTheme = title?.theme || 'text-semibold';
    const titleColor = title?.color || 'N900';

    const valueSize = value?.size || defaultColWidth;
    const valueTheme = value?.theme || 'text-regular';
    const valueColor = value?.color || 'N1000';
    const isPadded = value?.padded;

    const subValueTheme = subValue?.theme || 'small-regular';
    const subValueColor = subValue?.color || 'N1000';
    const italic = subValue?.italic !== false;

    return (
      <RowComponent
        gutters={false}
        className={cn(
          styles.row,
          textWrapEnabled && styles['text-wrap-enabled']
        )}
      >
        <ColComponent
          sm={titleSize}
          xl={titleSize}
          lg={titleSize}
          md={titleSize}
        >
          <Text
            theme={titleTheme}
            color={titleColor}
            uppercase={title?.uppercase}
          >
            {data.title}
          </Text>
        </ColComponent>
        <ColComponent
          sm={valueSize}
          xl={valueSize}
          lg={valueSize}
          md={valueSize}
        >
          {data.values.map(({ value, subValue }) => (
            <div
              key={this.accessor! + value}
              className={cn(styles.value, { [styles.padded]: isPadded })}
            >
              <div>
                <Text theme={valueTheme} color={valueColor}>
                  {value}
                </Text>
              </div>
              {subValue && (
                <div>
                  <Text
                    theme={subValueTheme}
                    color={subValueColor}
                    italic={italic}
                  >
                    {subValue}
                  </Text>
                </div>
              )}
            </div>
          ))}
        </ColComponent>
      </RowComponent>
    );
  };
}
