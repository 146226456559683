import React, { CSSProperties } from 'react';
import { HeaderGroup } from 'react-table';
import cn from 'classnames';
import { Button, ButtonSize } from 'components/Button';
import { IconType } from 'components/Icon';
import { OverflowTooltipCell } from 'components/OverflowTooltipCell';

import styles from './TableHeader.module.scss';

interface TableSortByToggleProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: CSSProperties;
  title?: string;
}

interface Props<T extends object> {
  className?: string;
  overflowed?: boolean;
  totalColumnsWidth: number;
  headerGroups: Array<HeaderGroup<T>>;
  resizable?: boolean;
  onSortClick?: () => void;
}

export const TableHeader: <T extends object>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  totalColumnsWidth,
  overflowed,
  resizable = false,
  headerGroups,
  className,
  onSortClick
}) => {
  return (
    <div
      className={cn(styles['table-header'], className)}
      style={{ width: totalColumnsWidth }}
    >
      {headerGroups.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => {
            const Container = overflowed ? 'div' : OverflowTooltipCell;
            const { key, ...headerProps } = column.getHeaderProps();
            const resizerProps = column.getResizerProps
              ? column.getResizerProps()
              : {};

            const sortToggleProps: TableSortByToggleProps = column.canSort
              ? column.getSortByToggleProps()
              : {};

            return (
              <div className={styles['cell-container']} key={key}>
                <Container
                  {...headerProps}
                  className={cn({
                    [styles.th]: true,
                    [styles.first]: index === 0,
                    [styles.overflowed]: overflowed
                  })}
                  data-testid='th'
                >
                  {column.render('Header')}
                </Container>

                {column.canSort && (
                  <Button
                    {...sortToggleProps}
                    onClick={(e) => {
                      sortToggleProps.onClick && sortToggleProps.onClick(e);
                      onSortClick && onSortClick();
                    }}
                    title=''
                    icon={IconType.Arrow}
                    size={ButtonSize.Small}
                    className={cn({
                      [styles['order-button']]: true,
                      [styles.active]: column.isSorted,
                      [styles.desc]: column.isSorted && column.isSortedDesc
                    })}
                    data-testid='sort-button'
                  />
                )}

                {!overflowed && resizable && (
                  <div
                    {...resizerProps}
                    data-testid='table-resizer'
                    className={styles['resizer-container']}
                  >
                    <div className={styles.resizer} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <div className={styles.separator} />
    </div>
  );
};
