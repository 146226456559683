import React, { ReactNode } from 'react';
import cn from 'classnames';
import { FormInputLabel } from 'components/FormInputLabel';
import { Col } from 'components/Grid/Col';
import { Row } from 'components/Grid/Row';
import { UnionColSize } from 'components/Grid/types';
import { useCustomTranslation } from 'hooks/translation';

import { Icon, IconType } from '../Icon';
import { Tooltip, TooltipIds } from '../Tooltip';

import styles from './InputContainer.module.scss';

interface Props {
  className?: string;
  label: ReactNode;
  asteriskTooltip?: string;
  required?: boolean;
  labelColSize?: UnionColSize;
  inputColSize?: UnionColSize;
  colClassName?: string;
  tooltipId?: string;
}

export const InputContainer: React.FC<Props> = ({
  label,
  required,
  asteriskTooltip = 'Required',
  children,
  className,
  labelColSize = 4,
  inputColSize = 8,
  colClassName,
  tooltipId = TooltipIds.DefaultPrimary
}) => {
  const { t } = useCustomTranslation();

  return (
    <Row className={cn(styles['input-container'], className)}>
      <Col md={labelColSize} className={colClassName}>
        <FormInputLabel className={styles.label}>
          {label}
          {required && (
            <Tooltip
              tooltipId={tooltipId}
              data={[
                {
                  mainTooltip: t(asteriskTooltip)
                }
              ]}
            >
              <span className={styles.icon} data-testid='asterisk'>
                <Icon iconType={IconType.Required} />
              </span>
            </Tooltip>
          )}
        </FormInputLabel>
      </Col>
      <Col md={inputColSize} className={colClassName}>
        {children}
      </Col>
    </Row>
  );
};
