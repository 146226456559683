import React from 'react';
import cn from 'classnames';
import { useReportDataValue } from 'components/ReportDataContainer';
import {
  EventsTreeBlockItem,
  EventsTreeBlockType
} from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';
import { ShoppingCart } from 'components/ShoppingCart';

import { BaseBlock } from '../BaseBlock';

import styles from './EventsTreeBlock.module.scss';

const FolderBlockFragment: React.FC<{ item: EventsTreeBlockItem }> = ({
  item
}) => {
  return (
    <div className={styles.folder}>
      <div className={styles['folder-title']}>{item.title}</div>
      <EventsTreeBlockCondition items={item.children} />
    </div>
  );
};

const FindingBlockFragment: React.FC<{ item: EventsTreeBlockItem }> = ({
  item
}) => {
  return (
    <div className={styles.finding}>
      <div className={styles['finding-title']}>{item.title}</div>
      {item.subItems && <ShoppingCart nodes={item.subItems} />}
      <EventsTreeBlockCondition items={item.children} />
    </div>
  );
};

const EventsTreeBlockCondition: React.FC<{
  items?: EventsTreeBlockItem[];
  isPadded?: boolean;
}> = ({ items = [], isPadded = true }) => {
  return (
    <div className={cn(isPadded && styles.padded)}>
      {items
        .map((item) => {
          if (item.type === EventsTreeBlockType.Hidden) {
            return (
              <EventsTreeBlockCondition
                items={item.children}
                isPadded={false}
                key={item.id}
              />
            );
          }

          if (item.type === EventsTreeBlockType.Folder) {
            return <FolderBlockFragment item={item} key={item.id} />;
          }

          if (item.type === EventsTreeBlockType.Finding) {
            return <FindingBlockFragment item={item} key={item.id} />;
          }

          return null;
        })
        .filter(Boolean)}
    </div>
  );
};

export class EventsTreeBlock extends BaseBlock<{}, any[]> {
  identifier = BlockIdentifier.EventsTreeBlock;

  Component: React.FC<{}> = () => {
    const items = useReportDataValue(this.accessor);

    return <EventsTreeBlockCondition items={items} isPadded={false} />;
  };
}
