import React from 'react';
import { Cell } from 'react-table';

import { MiniHeadModel } from '../MiniHeadModel';
import { StatusBadge, StatusBadgeTheme } from '../StatusBadge';
import { TableColumn } from './Table';

export const columns: Array<TableColumn<Record<string, any>>> = [
  {
    id: 'patientName',
    Header: 'Patient Name',
    accessor: 'patientName',
    order: 0,
    isSorted: true,
    isSortedDesc: false,
    isVisible: false
  },
  {
    id: 'age',
    Header: 'Age',
    accessor: 'age',
    order: 3,
    isVisible: true
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    width: 125,
    Cell: ({ value }: Cell) => (
      <StatusBadge theme={StatusBadgeTheme.Red} title={value} />
    ),
    order: 1,
    isVisible: true
  },
  {
    id: 'headModel',
    Header: 'Head Model',
    accessor: 'headModel',
    width: 125,
    disableSortBy: true,
    Cell: ({ value }: Cell) => <MiniHeadModel quarters={value} />,
    order: 2,
    isVisible: false
  },
  {
    id: 'gender',
    Header: 'Gender',
    accessor: 'gender',
    order: 4,
    isVisible: true
  },
  {
    id: 'patientId',
    Header: 'Patient ID',
    accessor: 'patientId',
    order: 6,
    isVisible: true
  },
  {
    id: 'assignedTo',
    Header: 'Assigned to',
    accessor: 'assignedTo',
    order: 5,
    isVisible: false
  },
  {
    id: 'tags',
    Header: 'Tags',
    accessor: 'tags',
    width: 125,
    order: 8,
    isVisible: true
  },
  {
    id: 'clinicalDiagnosis',
    Header: 'Clinical Diagnosis',
    accessor: 'clinicalDiagnosis',
    order: 10,
    isVisible: true
  },
  {
    id: 'insuranceNumber',
    Header: 'Insurance number',
    accessor: 'insuranceNumber',
    width: 151,
    order: 9,
    isVisible: false
  },
  {
    id: 'address',
    Header: 'Address',
    accessor: 'address',
    width: 250,
    order: 7,
    isVisible: true
  }
].map((item) => ({ ...item, minWidth: 110, isVisible: true }));
