import React from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonTheme } from 'components/Button';
import { IconType } from 'components/Icon';
import { IndexBadge, IndexBadgeTheme } from 'components/IndexBadge';

import styles from './IndexContainer.module.scss';

interface Props {
  isMoveUpDisabled?: boolean;
  isMoveDownDisabled?: boolean;
  title: string;
  className?: string;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

export const IndexContainer: React.FC<Props> = ({
  title,
  isMoveUpDisabled = false,
  isMoveDownDisabled = false,
  onMoveUp,
  onMoveDown,
  children,
  className
}) => (
  <div className={cn(styles['index-container'], className)}>
    <IndexBadge
      theme={IndexBadgeTheme.Violet}
      className={cn(styles['index-badge'], { [styles.hidden]: !title.length })}
    >
      {title}
    </IndexBadge>
    <div className={styles.content}>{children}</div>
    <div className={styles['arrows-container']}>
      <Button
        onClick={onMoveUp}
        disabled={isMoveUpDisabled}
        data-testid='move-up-group'
        size={ButtonSize.Small}
        className={styles.button}
        theme={ButtonTheme.HighlightedAlt}
        icon={IconType.Arrow}
      />
      <Button
        onClick={onMoveDown}
        data-testid='move-down-group'
        disabled={isMoveDownDisabled}
        size={ButtonSize.Small}
        className={cn(styles.button, styles['move-down'])}
        theme={ButtonTheme.HighlightedAlt}
        icon={IconType.Arrow}
      />
    </div>
  </div>
);
