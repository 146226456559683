import React from 'react';
import { IndicatorProps } from 'react-select';
import { SelectOptionType } from 'components/Select';

import { Icon, IconType } from '../Icon';

import styles from './SelectClearIndicator.module.scss';

export const SelectClearIndicator: <T extends SelectOptionType>(
  p: IndicatorProps<T>
) => React.ReactElement<IndicatorProps<T>> = (props) => {
  const {
    innerProps: { ref, tabIndex = -1, ...restInnerProps }
  } = props;

  return (
    <button
      {...restInnerProps}
      ref={ref}
      className={styles['clear-button']}
      tabIndex={tabIndex}
    >
      <Icon iconType={IconType.Close} className={styles['close-icon']} />
    </button>
  );
};
