import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { IconType } from 'components/Icon';
import { Ladder } from 'components/Ladder';

import styles from './Accordion.module.scss';

interface Props {
  renderHeader: (arrowBtn: ReactNode) => ReactNode;
  isActive: boolean;
  toggleDisabled?: boolean;
  onArrowClick: React.ComponentProps<typeof Button>['onClick'];
  hasLadder?: boolean;
  className?: string;
  ladderClassName?: string;
  contentClassName?: string;
  btnTabIndex?: number;
}

export const Accordion: React.FC<Props> = ({
  renderHeader,
  toggleDisabled = false,
  isActive,
  onArrowClick,
  hasLadder = false,
  className,
  ladderClassName,
  contentClassName,
  children,
  btnTabIndex = -1
}) => (
  <div className={cn(styles.container, className)}>
    {renderHeader(
      <Button
        onClick={onArrowClick}
        disabled={toggleDisabled}
        icon={IconType.ChevronDown}
        theme={ButtonTheme.SecondaryTransparent}
        className={cn(styles.btn, isActive && styles.active)}
        tabIndex={btnTabIndex}
      />
    )}
    {isActive && (
      <div className={cn(styles.content, contentClassName)}>
        {hasLadder ? (
          <Ladder className={ladderClassName}>{children}</Ladder>
        ) : (
          children
        )}
      </div>
    )}
  </div>
);
