import React, { LabelHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './FormInputLabel.module.scss';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}

export const FormInputLabel: React.FC<Props> = ({
  className,
  children,
  ...labelProps
}) => (
  <label {...labelProps} className={cn(className, styles['form-input-label'])}>
    {children}
  </label>
);
