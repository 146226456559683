export enum BlockIdentifier {
  RootContainer = 'root-container',
  GenericSection = 'generic-section',
  Row = 'grid-row',
  Col = 'grid-col',
  Title = 'title',
  Logotype = 'logotype',
  KeyValue = 'key-value',
  Padding = 'padding',
  HeadModel = 'head-model',
  MultiRowProperty = 'multi-row-property',
  BlockFragment = 'block-fragment',
  EventsTreeBlock = 'events-tree',
  Float = 'float',
  IndivisibleBlock = 'indivisible-block',
  Images = 'images'
}
