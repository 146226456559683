import React, { EventHandler, MouseEvent } from 'react';
import classnames from 'classnames';
import { Avatar } from 'components/Avatar';

import styles from './UserHeading.module.scss';

interface Props {
  className?: string;
  name: string;
  position?: string;
  avatarUrl?: string;
  onClick?: EventHandler<MouseEvent<HTMLButtonElement>>;
}

export const UserHeading: React.FC<Props> = ({
  className,
  name,
  onClick,
  position,
  avatarUrl = ''
}) => {
  return (
    <button
      className={classnames(styles['user-heading'], className)}
      onClick={onClick}
    >
      <div className={styles.titles}>
        <span className={styles.name}>{name}</span>
        {position && (
          <span data-testid='user-heading-position' className={styles.position}>
            {position}
          </span>
        )}
      </div>
      <Avatar url={avatarUrl} name={name} className={styles.avatar} />
    </button>
  );
};
