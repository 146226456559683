import React, { FocusEvent, Fragment, useCallback, useState } from 'react';
import cn from 'classnames';
import { ErrorMessage } from 'components/ErrorMessage';
import { useConvertTime } from 'hooks/useConvertTime';

import styles from './FormTimeInput.module.scss';

interface Props {
  className?: string;
  value?: number | '';
  onChange: (milliseconds: number | null) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: () => void;
  hasFullFormat?: boolean;
  error?: string;
  disabled?: boolean;
}

export const FormTimeInput = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      value,
      onChange,
      hasFullFormat = false,
      error,
      disabled,
      ...props
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    const {
      hours,
      minutes,
      seconds,
      onChangeHours,
      onChangeMinutes,
      onChangeSeconds,
      onBlur
    } = useConvertTime(hasFullFormat, onChange, value);

    const onComponentFocus = useCallback(
      (e) => {
        setHasFocus(true);
        props.onFocus?.(e);
      },
      [props]
    );
    const onComponentBlur = useCallback(() => {
      setHasFocus(false);
      props.onBlur?.();
    }, [props]);

    return (
      <Fragment>
        <div
          className={cn(
            styles['form-time-input'],
            [hasFullFormat && styles['full-format']],
            className
          )}
          ref={ref}
          onFocus={onComponentFocus}
          onBlur={onComponentBlur}
        >
          {hasFullFormat && (
            <Fragment>
              <div
                className={cn(styles['input-wrap'], styles['hours-container'])}
              >
                <input
                  value={hours}
                  className={cn(styles.input, styles.hours, {
                    [styles.error]: !!error,
                    [styles['has-focus']]: hasFocus
                  })}
                  onChange={onChangeHours}
                  onBlur={onBlur}
                  placeholder='hh'
                  disabled={disabled}
                />
                {hours !== '' && (
                  <span className={styles['input-unit']}>h</span>
                )}
              </div>

              <div
                className={cn(
                  styles['input-wrap'],
                  styles['minutes-container']
                )}
              >
                <input
                  value={minutes}
                  onChange={onChangeMinutes}
                  className={cn(styles.input, styles.minutes, {
                    [styles.error]: !!error,
                    [styles['has-focus']]: hasFocus
                  })}
                  onBlur={onBlur}
                  placeholder='mm'
                  disabled={disabled}
                />
                {minutes !== '' && (
                  <span className={styles['input-unit']}>m</span>
                )}
              </div>
            </Fragment>
          )}
          <div
            className={cn(styles['input-wrap'], styles['seconds-container'])}
          >
            <input
              value={seconds}
              className={cn(styles.input, styles.seconds, {
                [styles.error]: !!error,
                [styles['has-focus']]: hasFocus
              })}
              onChange={onChangeSeconds}
              onBlur={onBlur}
              placeholder='ss'
              disabled={disabled}
            />
            {seconds !== '' && <span className={styles['input-unit']}>s</span>}
          </div>
        </div>
        <ErrorMessage error={error} />
      </Fragment>
    );
  }
);
