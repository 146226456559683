import React from 'react';
import cn from 'classnames';

import styles from './FormSection.module.scss';

interface Props {
  title: string;
  className?: string;
}

export const FormSection: React.FC<Props> = ({
  title,
  className,
  children
}) => {
  return (
    <div className={cn(styles['form-section'], className)}>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </div>
  );
};
