import React from 'react';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

import styles from './RootContainer.module.scss';

export class RootContainer extends BaseBlock {
  identifier = BlockIdentifier.RootContainer;

  Component: React.FC = ({ children }) => {
    return <div className={styles['root-container']}>{children}</div>;
  };
}
