import React from 'react';
import cn from 'classnames';

import { Icon } from '../Icon';
import {
  StatusIndicatorIcon,
  StatusIndicatorStatus
} from './StatusIndicator.enum';

import styles from './StatusIndicator.module.scss';

interface Props {
  status?: StatusIndicatorStatus;
  className?: string;
}

export const StatusIndicator: React.FC<Props> = ({
  status = StatusIndicatorStatus.Saved,
  children,
  className
}) => (
  <div className={cn(styles['status-indicator'], styles[status], className)}>
    <span className={styles['icon-container']}>
      {status === StatusIndicatorStatus.Loading ? (
        <div data-testid='dots-spinner' className={styles.spinner} />
      ) : (
        <Icon iconType={StatusIndicatorIcon[status]} className={styles.icon} />
      )}
    </span>
    {children && <span className={styles.title}>{children}</span>}
  </div>
);
