import React from 'react';
import cn from 'classnames';

import styles from './Sidebar.module.scss';

interface Props {
  className?: string;
}

export const Sidebar: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.sidebar, className)}>{children}</div>;
};
