import React from 'react';
import cn from 'classnames';

import { TextBadgeTheme } from './TextBadge.enum';

import styles from './TextBadge.module.scss';

interface Props {
  theme?: TextBadgeTheme;
  className?: string;
  title: string;
}

export const TextBadge: React.FC<Props> = ({
  theme = TextBadgeTheme.Grey,
  title,
  className
}) => (
  <span className={cn(styles['text-badge'], styles[theme], className)}>
    {title}
  </span>
);
