export type NumericColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export type UnionColSize = 'auto' | NumericColSize | 12;
export type ColOrderValue = 'first' | 'last' | NumericColSize | 12;

export type DetailedColSize = {
  size?: UnionColSize;
  offset?: NumericColSize;
  order?: ColOrderValue;
};

export type ColSize = UnionColSize | DetailedColSize;

export type NumericRowSize = 1 | 2 | 3 | 4 | 5 | 6;

export enum Breakpoint {
  md = 'md',
  sm = 'sm',
  lg = 'lg',
  xl = 'xl'
}

export enum Justify {
  start = 'start',
  end = 'end',
  center = 'center',
  between = 'between',
  around = 'around'
}

export enum AlignItems {
  baseline = 'baseline',
  center = 'center',
  end = 'end',
  start = 'start',
  stretch = 'stretch'
}

export enum AlignSelf {
  baseline = 'baseline',
  center = 'center',
  end = 'end',
  start = 'start',
  stretch = 'stretch',
  auto = 'auto'
}

export enum AlignContent {
  around = 'around',
  between = 'between',
  center = 'center',
  end = 'end',
  start = 'start',
  stretch = 'stretch'
}

export enum SizeKind {
  col = 'col',
  offset = 'offset',
  order = 'order',
  rowCols = 'row-cols',
  row = 'row',
  container = 'container'
}

export type SizesProps<T> = { [key in Breakpoint]?: T };

export type RowSizes = SizesProps<NumericRowSize>;
export type ColSizes = SizesProps<ColSize>;

export type JustifySizes = {
  direction: Justify;
  size?: Breakpoint;
};

export type AlignContentSizes = {
  type: 'content';
  direction: AlignContent;
  size?: Breakpoint;
};

export type AlignItemsSizes = {
  type: 'items';
  direction: AlignItems;
  size?: Breakpoint;
};

export type AlignSelfSizes = {
  type: 'self';
  direction: AlignSelf;
  size?: Breakpoint;
};

export type AlignAnySizes =
  | AlignContentSizes
  | AlignItemsSizes
  | AlignSelfSizes;
