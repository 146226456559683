import { useMemo } from 'react';
import { ValueOf } from 'common-types/type-helpers';
import memoize from 'memoizee';

import {
  Breakpoint,
  ColOrderValue,
  ColSize,
  ColSizes,
  NumericColSize,
  NumericRowSize,
  RowSizes,
  SizeKind,
  UnionColSize
} from './types';

import './grid.scss';

export const makeGridClassName = memoize(
  <T extends UnionColSize | NumericColSize | ColOrderValue | NumericRowSize>(
    type: SizeKind,
    breakpoint?: Breakpoint | 'fluid',
    value?: T
  ): string => {
    const style: Array<Breakpoint | 'fluid' | SizeKind | T> = [];
    style.push(type);

    breakpoint && style.push(breakpoint);
    value && style.push(value);

    return style.join('-');
  }
);

export const mapColSize = memoize(
  (breakpoint: Breakpoint, size: ColSize): string[] => {
    if (typeof size === 'object') {
      const { offset, order, size: sizeValue } = size;
      const styles = [];

      if (!!sizeValue) {
        styles.push(makeGridClassName(SizeKind.col, breakpoint, sizeValue));
      } else {
        styles.push(makeGridClassName(SizeKind.col, breakpoint));
      }

      !!offset &&
        styles.push(makeGridClassName(SizeKind.offset, breakpoint, offset));
      !!order &&
        styles.push(makeGridClassName(SizeKind.order, breakpoint, order));

      return styles;
    }
    return [makeGridClassName(SizeKind.col, breakpoint, size)];
  }
);

export const mapRowSize = memoize(
  (breakpoint?: Breakpoint, size?: NumericRowSize): string[] => {
    return [
      makeGridClassName(
        breakpoint ? SizeKind.rowCols : SizeKind.row,
        breakpoint,
        size
      )
    ];
  }
);

export const useColSize = (
  breakpoint: Breakpoint,
  size?: ValueOf<ColSizes>
): string[] => {
  return useMemo(
    () => (!!size ? mapColSize(breakpoint, size) : []),
    [breakpoint, size]
  );
};

export const useRowColsSize = (
  breakpoint?: Breakpoint,
  size?: ValueOf<RowSizes>
): string[] => {
  return useMemo(() => {
    if (!size && !breakpoint) {
      return mapRowSize();
    }

    return !!size ? mapRowSize(breakpoint, size) : [];
  }, [breakpoint, size]);
};

export const useColPlainStyles = (): string => {
  return useMemo(() => 'col', []);
};

export const useRowPlainStyles = (gutters?: boolean): string[] => {
  return useMemo(() => {
    const styles = ['row'];

    !gutters && styles.push('no-gutters');

    return styles;
  }, [gutters]);
};

export const useContainerSize = (
  breakpoint?: Breakpoint,
  fluid?: boolean
): string => {
  return useMemo(() => {
    if (breakpoint) {
      return makeGridClassName(SizeKind.container, breakpoint);
    }

    if (fluid) {
      return makeGridClassName(SizeKind.container, 'fluid');
    }

    return makeGridClassName(SizeKind.container);
  }, [breakpoint, fluid]);
};
