import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isValidUrl } from 'helpers/validators';

import styles from './Avatar.module.scss';

interface Props {
  className?: string;
  name: string;
  url: string;
}

export const Avatar: React.FC<Props> = ({ className, name, url }) => {
  const [urlIsBroken, setUrlIsBroken] = useState(false);
  const urlIsValid = useMemo(() => isValidUrl(url), [url]);
  const initials = useMemo(() => name?.charAt(0) || '?', [name]);
  const onError = useCallback(() => {
    setUrlIsBroken(true);
  }, []);

  useEffect(() => {
    setUrlIsBroken(false);
  }, [url]);

  return urlIsValid && !urlIsBroken ? (
    <img
      className={classnames(styles.avatar, className)}
      src={url}
      alt={`${name} avatar`}
      onError={onError}
    />
  ) : (
    <div
      data-testid='avatar-placeholder'
      className={classnames(styles.avatar, className)}
    >
      {initials}
    </div>
  );
};
