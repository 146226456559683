import React from 'react';
import { Col as ColComponent } from 'components/Grid/Col';
import {
  AlignContentSizes,
  AlignItemsSizes,
  AlignSelfSizes,
  ColSize
} from 'components/Grid/types';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

interface Metadata {
  size?: ColSize;
  alignContent?: AlignContentSizes | AlignContentSizes[];
  alignItems?: AlignItemsSizes | AlignItemsSizes[];
  alignSelf?: AlignSelfSizes | AlignSelfSizes[];
}

export class Col extends BaseBlock<Metadata> {
  identifier = BlockIdentifier.Col;

  Component: React.FC<Metadata> = ({
    children,
    size,
    alignContent,
    alignItems,
    alignSelf
  }) => {
    return (
      <ColComponent
        sm={size}
        xl={size}
        lg={size}
        md={size}
        alignContent={alignContent}
        alignItems={alignItems}
        alignSelf={alignSelf}
      >
        {children}
      </ColComponent>
    );
  };
}
