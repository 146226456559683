import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes
} from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

interface Props
  extends Exclude<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type'
  > {
  label?: string;
  className?: string;
  checkmarkClassName?: string;
  isFocused?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ label, className, checkmarkClassName, isFocused, ...inputProps }, ref) => (
    <label
      className={cn(
        styles.checkbox,
        inputProps?.disabled && styles['is-disabled'],
        isFocused && styles['is-focused'],
        className
      )}
    >
      {label}
      <input
        ref={ref}
        {...inputProps}
        type='checkbox'
        className={styles['checkbox-button']}
      />
      <span className={cn(styles.checkmark, checkmarkClassName)} />
    </label>
  )
);
