import React from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';
import { ErrorTheme } from './GeneralError.enum';

import styles from './GeneralError.module.scss';

interface Props {
  title: string;
  icon?: IconType;
  className?: string;
  theme?: ErrorTheme;
  style?: React.CSSProperties;
  containerClassName?: string;
}

export const GeneralError: React.FC<Props> = ({
  className,
  icon,
  title,
  children,
  theme = ErrorTheme.Primary,
  style,
  containerClassName
}) => (
  <div className={cn(styles['general-error'], className)} style={style}>
    <div className={cn(styles['main-container'], containerClassName)}>
      {icon && (
        <div className={styles['icon-container']}>
          <Icon
            iconType={icon}
            className={styles.icon}
            data-testid='empty-state-icon'
          />
        </div>
      )}
      <p className={cn(styles.title, styles[theme])}>{title}</p>
      {children}
    </div>
  </div>
);
