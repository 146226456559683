import React from 'react';
import cn from 'classnames';

import { IconComponent, IconType } from './Icon.enum';

import styles from './Icon.module.scss';

interface Props extends React.SVGAttributes<SVGElement> {
  iconType: IconType;
}

export const Icon: React.FC<Props> = ({ iconType, className, ...props }) => {
  const Component = IconComponent[iconType];

  return <Component {...props} className={cn(styles.icon, className)} />;
};
