import React, { useMemo } from 'react';
import cn from 'classnames';

import { CellTheme } from './TableEmptyCell.enum';

import styles from './TableEmptyCell.module.scss';

interface Props {
  theme?: CellTheme;
  className?: string;
}

const getRandomSize = () => {
  const cellSizes = [112, 80, 100, 92, 104, 117];
  const randomIndex = Math.floor(Math.random() * cellSizes.length);
  return cellSizes[randomIndex] || cellSizes[0];
};

export const TableEmptyCell: React.FC<Props> = ({
  className,
  theme = CellTheme.Primary
}) => {
  const width = useMemo(() => getRandomSize(), []);

  return (
    <span
      style={{ width }}
      className={cn(styles['table-empty-cell'], styles[theme], className)}
    />
  );
};
