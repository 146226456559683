import React from 'react';
import { NavTabTheme } from 'components/NavTab/NavTab.enum';

import { NavTab } from '../NavTab';

interface Tab {
  id: number;
  label: string;
}

interface Props {
  tabs: Array<Tab>;
  activeTabId: number;
}

export const NavTabs: React.FC<Props> = ({ tabs, activeTabId, ...rest }) => (
  <div>
    {tabs.map(({ id, label }) => (
      <NavTab
        {...rest}
        key={id}
        label={label}
        component='button'
        active={activeTabId === id}
        theme={NavTabTheme.Underlined}
      />
    ))}
  </div>
);
