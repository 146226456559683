import React, { ComponentProps, ElementType } from 'react';
import cn from 'classnames';

import { ReactComponent as Curve } from '../../assets/svg/curve.svg';
import { StatusMark, StatusMarkState } from '../StatusMark';
import { NavTabTheme } from './NavTab.enum';

import styles from './NavTab.module.scss';

interface Props<T> {
  component: T;
  label: string;
  status?: StatusMarkState;
  className?: string;
  active?: boolean;
  theme: NavTabTheme;
  curveLeftClassName?: string;
}

export const NavTab = <T extends ElementType>({
  component,
  className,
  label,
  status,
  active,
  theme,
  curveLeftClassName,
  ...rest
}: Props<T> & ComponentProps<T>) => {
  const TabComponent = component;

  return (
    <TabComponent
      {...rest}
      data-testid={active && 'active-tab'}
      className={cn(
        styles.tab,
        styles[theme],
        active && styles.active,
        className
      )}
    >
      {theme === NavTabTheme.Merged && (
        <Curve
          className={cn(styles.curve, styles['curve-left'], curveLeftClassName)}
          data-testid='curve'
        />
      )}
      {label}
      {status && (
        <StatusMark status={status} className={styles['status-mark']} />
      )}
      {theme === NavTabTheme.Merged && (
        <Curve
          className={cn(styles.curve, styles['curve-right'])}
          data-testid='curve'
        />
      )}
    </TabComponent>
  );
};

NavTab.activeStyle = styles.active;
