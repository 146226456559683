import React from 'react';
import cn from 'classnames';

import { IndexBadgeTheme } from './IndexBadge.enum';

import styles from './IndexBadge.module.scss';

interface Props {
  theme?: IndexBadgeTheme;
  className?: string;
}

export const IndexBadge: React.FC<Props> = ({
  theme = IndexBadgeTheme.Blue,
  children,
  className
}) => (
  <div className={cn(styles['index-badge'], styles[theme], className)}>
    {children}
  </div>
);
