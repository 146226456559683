import { MultiRowDataType } from 'components/ReportDataContainer/ReportDataContainer';
import { isNullish } from 'helpers/validators';

const getNonEmptyValues = (
  values?: MultiRowDataType['values']
): MultiRowDataType['values'] => {
  if (!Array.isArray(values)) {
    return [];
  }

  return values.filter(({ value }) => !isNullish(value) && value !== '-');
};

export const isEmptyMultiRowData = (data?: MultiRowDataType) => {
  if (!data) {
    return true;
  }

  const hasTitle = !isNullish(data.title);
  const nonEmptyValues = getNonEmptyValues(data.values);
  const hasValues = nonEmptyValues.length !== 0;

  return !hasTitle || !hasValues;
};
