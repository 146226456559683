import React from 'react';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { useReportDataValue } from '../../../ReportDataContainer';
import { BaseBlock } from '../BaseBlock';

import styles from './Logotype.module.scss';

interface Metadata {}

export class Logotype extends BaseBlock<Metadata, string> {
  identifier = BlockIdentifier.Logotype;

  Component: React.FC = () => {
    const value = useReportDataValue(this.accessor);

    if (!value) {
      return null;
    }

    return <img className={styles.logotype} src={value} alt='Logo' />;
  };
}
