import React, { FC } from 'react';
import cn from 'classnames';

import { Button, ButtonTheme } from '../Button';
import { IconType } from '../Icon';

import styles from './RegionTags.module.scss';

export interface Tag {
  id: number;
  title: string;
  isRemovable?: boolean;
}

type Props = Tag & {
  onRemoveTag?: (id: number) => void;
  className?: string;
  isHidden?: boolean;
};

export const RegionTag: FC<Props> = ({
  id,
  title,
  onRemoveTag,
  isHidden,
  isRemovable,
  className
}) => (
  <span
    key={title}
    className={cn(styles.tag, { [styles.hidden]: isHidden }, className)}
    data-testid='region-tag'
  >
    <span>{title}</span>
    {isRemovable && onRemoveTag && (
      <Button
        title=''
        icon={IconType.Close}
        theme={ButtonTheme.SecondaryTransparent}
        className={styles['remove-button']}
        onClick={() => onRemoveTag(id)}
      />
    )}
  </span>
);
