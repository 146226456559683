import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'components/Icon';

import { ButtonSize, ButtonTheme } from './Button.enum';

import styles from './Button.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  size?: ButtonSize;
  theme?: ButtonTheme;
  icon?: IconType;
  isIconActive?: boolean;
  iconClassName?: string;
  titleClassName?: string;
}

export const Button: React.FC<Props> = ({
  title,
  icon,
  isIconActive,
  theme = ButtonTheme.Secondary,
  size = ButtonSize.Regular,
  className,
  iconClassName,
  titleClassName,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn(
        styles.button,
        styles[size],
        styles[theme],
        icon && styles.iconed,
        className
      )}
    >
      {icon && (
        <Icon
          iconType={icon}
          data-testid='button-icon'
          className={cn(
            styles.icon,
            isIconActive && styles['icon-active'],
            iconClassName
          )}
        />
      )}
      {title && <span className={titleClassName}>{title}</span>}
    </button>
  );
};
