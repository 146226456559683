import React, { useCallback, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import cn from 'classnames';

import { MessageSnackBarTheme } from './MessageSnackBar.enum';

import styles from './MessageSnackBar.module.scss';

interface Props {
  theme?: MessageSnackBarTheme;
  title?: string;
  className?: string;
  hidden?: boolean;
}

const titleClassNames = {
  enter: styles['slide-enter'],
  enterActive: styles['slide-enter-active'],
  exit: styles['slide-exit'],
  exitActive: styles['slide-exit-active']
};

export const MessageSnackBar: React.FC<Props> = ({
  theme = MessageSnackBarTheme.Red,
  title,
  className,
  hidden = false
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const handleTransitionEnd = useCallback(
    (done: () => void) =>
      nodeRef.current?.addEventListener('transitionend', done, false),
    []
  );

  return (
    <div
      className={cn(
        styles['message-snack-bar'],
        styles[theme],
        { [styles.hidden]: !!hidden },
        className
      )}
    >
      {title && (
        <SwitchTransition>
          <CSSTransition
            key={title}
            addEndListener={handleTransitionEnd}
            classNames={titleClassNames}
            timeout={200}
            nodeRef={nodeRef}
          >
            <div ref={nodeRef}>{title}</div>
          </CSSTransition>
        </SwitchTransition>
      )}
    </div>
  );
};
