import React, { FC, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { ReactTransformer, Transformer } from './Transformer';

interface Props {
  html: string;
  transformer?: Transformer;
}

export const HTMLParser: FC<Props> = ({
  html,
  transformer = new ReactTransformer()
}) => (
  <Fragment>
    {ReactHtmlParser(transformer.sanitize(html), {
      transform: transformer.transform.bind(transformer)
    })}
  </Fragment>
);
