import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'components/Icon';

import { WarningMessageTheme } from './WarningMessage.enum';

import styles from './WarningMessage.module.scss';

interface Props {
  theme: WarningMessageTheme;
  content: ReactNode;
  className?: string;
}

export const WarningMessage: React.FC<Props> = ({
  theme,
  className,
  content
}) => (
  <div className={cn(styles['warning-message'], styles[theme], className)}>
    <Icon iconType={IconType.Warning} className={styles.icon} />
    <div className={styles.content}>{content}</div>
  </div>
);
