import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

type TimeInput = string | number;

interface TimeSpan {
  seconds: TimeInput;
  minutes: TimeInput;
  hours: TimeInput;
}

export const splitTimeSpan = (
  hasFullFormat: boolean,
  milliseconds?: number | ''
): TimeSpan => {
  if (milliseconds === undefined || milliseconds === '') {
    return {
      seconds: '',
      minutes: '',
      hours: ''
    };
  }

  let remainingTime = milliseconds / 1000;

  if (!hasFullFormat) {
    return {
      seconds: Number(remainingTime.toFixed(1)),
      minutes: '',
      hours: ''
    };
  }

  const hours = Math.floor(remainingTime / 3600);
  remainingTime -= hours * 3600;

  const minutes = Math.floor(remainingTime / 60);
  remainingTime -= minutes * 60;

  const seconds = Number(remainingTime.toFixed(1));

  return {
    seconds: seconds || '',
    minutes: minutes || '',
    hours: hours || ''
  };
};

export const useConvertTime = (
  hasFullFormat: boolean,
  onChange: (newMilliseconds: number | null) => void,
  value?: number | ''
) => {
  const timeSpan = useMemo(
    () => splitTimeSpan(hasFullFormat, value),
    [hasFullFormat, value]
  );

  const [seconds, setSeconds] = useState<TimeInput>(timeSpan.seconds);
  const [minutes, setMinutes] = useState<TimeInput>(timeSpan.minutes);
  const [hours, setHours] = useState<TimeInput>(timeSpan.hours);

  useEffect(() => {
    setSeconds(timeSpan.seconds || '');
    setMinutes(timeSpan.minutes || '');
    setHours(timeSpan.hours || '');
  }, [timeSpan.seconds, timeSpan.minutes, timeSpan.hours]);

  const onChangeHours = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (/^\d+?$/.test(event.target.value) || !event.target.value) {
      setHours(event.target.value);
    }
  }, []);

  const onChangeMinutes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (/^\d+?$/.test(event.target.value) || !event.target.value) {
        setMinutes(event.target.value);
      }
    },
    []
  );

  const onChangeSeconds = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (
        /^(\d+)([.]?\d{0,1})?$/.test(event.target.value) ||
        !event.target.value
      ) {
        setSeconds(event.target.value);
      }
    },
    []
  );

  const onBlur = useCallback(() => {
    const milliseconds =
      seconds || minutes || hours
        ? (Number(seconds) + Number(minutes) * 60 + Number(hours) * 3600) * 1000
        : null;

    if (Number(seconds) === 0) {
      setSeconds('');
    }
    if (Number(minutes) === 0) {
      setMinutes('');
    }
    if (Number(hours) === 0) {
      setHours('');
    }
    onChange(milliseconds);
  }, [seconds, minutes, hours, onChange]);

  return {
    hours,
    minutes,
    seconds,
    onChangeHours,
    onChangeMinutes,
    onChangeSeconds,
    onBlur
  };
};
