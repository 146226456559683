import React from 'react';
import { KeyValueRow, KeyValueRowDirection } from 'components/KeyValueRow';
import {
  KeyValueDataType,
  useReportDataValue
} from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

interface Metadata {
  reverse?: boolean;
  vertical?: boolean;
}

export class KeyValue extends BaseBlock<Metadata, KeyValueDataType> {
  identifier = BlockIdentifier.KeyValue;

  Component: React.FC<Metadata> = ({ reverse, vertical }) => {
    const result = useReportDataValue(this.accessor);

    if (!result?.key || !result?.value) {
      return null;
    }

    return (
      <KeyValueRow
        title={result.key}
        value={result.value}
        direction={
          vertical
            ? KeyValueRowDirection.Vertical
            : KeyValueRowDirection.Horizontal
        }
        reverse={reverse}
      />
    );
  };
}
