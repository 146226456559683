import React from 'react';
import { useReportDataValue } from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';
import { Text } from 'components/Text';
import { TextColors, TextThemes } from 'components/Text/Text';

import { BaseBlock } from '../BaseBlock';

interface Metadata {
  theme?: TextThemes;
  color?: TextColors;
}

export class Title extends BaseBlock<Metadata, string | number> {
  identifier = BlockIdentifier.Title;

  Component: React.FC<Metadata> = ({ theme, color }) => {
    const value = useReportDataValue(this.accessor);

    if (value === undefined) {
      return null;
    }

    return (
      <Text theme={theme} color={color}>
        {value}
      </Text>
    );
  };
}
