import React from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';
import { StatusMarkState } from './StatusMark.enum';

import styles from './StatusMark.module.scss';

interface Props {
  status?: StatusMarkState;
  className?: string;
}

export const StatusMark: React.FC<Props> = ({
  status = StatusMarkState.Uncompleted,
  className
}) => (
  <div
    data-testid='status-mark'
    className={cn(styles['status-mark'], styles[status], className)}
  >
    <Icon iconType={IconType.Check} className={styles.icon} />
  </div>
);
