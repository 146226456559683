export class InputUnitConfig {
  constructor(
    readonly value: Readonly<{
      min: number;
      max: number;
    }> = {
      min: 0,
      max: Infinity
    },
    readonly allowedCharacters: Array<Array<string>> = []
  ) {}

  getRoundedValue = (value: number) => {
    const { min = 0, max } = this.value;

    return value < min ? min : max;
  };

  isValidLength = (value: string) => {
    const { max } = this.value;

    const minLength = 0;
    const maxLength = max ? String(max).length : Infinity;

    return value.length <= maxLength && value.length >= minLength;
  };

  isValidChar = (value: string) => {
    const allowedCharacters = this.allowedCharacters;

    if (!allowedCharacters.length || !value) {
      return true;
    }

    const splittedValue = value.split('');
    return splittedValue.every((value: string, index) => {
      if (allowedCharacters[index]?.length) {
        return allowedCharacters[index].includes(value);
      }
      return true;
    });
  };
}
