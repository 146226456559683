import React, { useState } from 'react';
import { Calendar, DayValue } from 'react-modern-calendar-datepicker';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';
import { isBrowser } from 'helpers/isBrowser';
import { FormInput } from 'index';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

import styles from './DatePicker.module.scss';

if (isBrowser()) {
  require('rc-time-picker/assets/index.css');
  require('react-modern-calendar-datepicker/lib/DatePicker.css');
}

interface Props {
  defaultValue?: string;
  withTime?: boolean;
  readOnly?: boolean;
  error?: boolean;
  className?: string;
  getValue: (value: string) => void;
}

export const DatePicker: React.FC<Props> = ({
  defaultValue,
  withTime,
  readOnly,
  error,
  className,
  getValue
}) => {
  const formatCalendarDate = (value: string) => {
    const today = new Date();
    const date = value.split(',')[0].split('/');
    if (
      value.length < 10 ||
      Number(date[2]) > today.getFullYear() + 50 ||
      Number(date[2]) < today.getFullYear() - 100
    )
      return null;

    return {
      day: Number(date[1]),
      month: Number(date[0]),
      year: Number(date[2])
    };
  };

  const [value, setValue] = useState<string>(defaultValue || '');
  const [selectedDay, setSelectedDay] = useState<DayValue>(
    defaultValue ? formatCalendarDate(defaultValue) : null
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [isTimeOpen, setIsTimeOpen] = useState<boolean>(false);

  return (
    <div className={classnames(styles['date-picker'], className)}>
      <FormInput
        placeholder={`MM/dd/yyyy${withTime ? ', hh:mm a' : ''}`}
        mask={`99/99/9999${withTime ? ', 99:99 aa' : ''}`}
        maskChar=''
        readOnly={readOnly}
        onClick={() => setIsCalendarOpen(true)}
        error={
          error ||
          new Date() < new Date(value) ||
          (value.length > 0 && value.length < (withTime ? 19 : 10)) ||
          (value && !new Date(value).getDate())
            ? 'Invalid date'
            : undefined
        }
        value={value}
        onChange={(val) => {
          setValue(val.target.value);
          setSelectedDay(formatCalendarDate(val.target.value));
        }}
        onBlur={() => {
          if (!isCalendarOpen && !isCalendarOpen) getValue(value);
        }}
      />
      <div className={styles['date-picker__calendar']}>
        <OutsideClickHandler
          onOutsideClick={() => {
            if (isCalendarOpen) {
              setIsCalendarOpen(false);
              getValue(value);
            }
          }}
        >
          <Calendar
            value={selectedDay}
            onChange={(val) => {
              const formattedResult = val
                ? `${String(val.month).padStart(2, '0')}/${String(
                    val.day
                  ).padStart(2, '0')}/${val.year}${
                    withTime ? ', 00:00 AM' : ''
                  }`
                : '';

              setIsCalendarOpen(false);
              setSelectedDay(val);
              setValue(formattedResult);
              if (withTime) {
                setIsTimeOpen(true);
              } else {
                getValue(formattedResult);
              }
            }}
            calendarClassName={classnames(
              styles['date-picker__calendar__picker'],
              { [styles['hidden']]: !isCalendarOpen }
            )}
            calendarTodayClassName={
              styles['date-picker__calendar__picker__today']
            }
            calendarSelectedDayClassName={
              styles['date-picker__calendar__picker__selected']
            }
          />
        </OutsideClickHandler>
        {isTimeOpen && (
          <div className={styles['date-picker__calendar__time']}>
            <TimePicker
              open
              onClose={() => {
                setIsTimeOpen(false);
                getValue(value);
              }}
              onChange={(val) => {
                setValue(
                  (prevValue) =>
                    `${prevValue.substring(0, 12)}${val.format('hh:mm A')}`
                );
              }}
              use12Hours
              defaultValue={moment().hour(0).minute(0)}
              clearIcon={<></>}
              showSecond={false}
              className={styles['date-picker__calendar__time__input']}
              popupClassName={styles['date-picker__calendar__time__picker']}
            />
          </div>
        )}
      </div>
    </div>
  );
};
