import React from 'react';
import SwitchIOS, { ReactSwitchProps } from 'react-switch';

import styles from './Switch.module.scss';

interface Props {
  checked: boolean;
  onChange: ReactSwitchProps['onChange'];
  disabled?: boolean;
}

export const Switch: React.FC<Props> = ({
  checked,
  onChange,
  disabled = false
}) => (
  <SwitchIOS
    width={30}
    height={18}
    checked={checked}
    handleDiameter={14}
    checkedIcon={false}
    uncheckedIcon={false}
    onChange={onChange}
    activeBoxShadow='0 0 0 0'
    onColor={styles.Pr500}
    offColor={styles.N200}
    disabled={disabled}
  />
);
