import React, { useContext } from 'react';

export interface KeyValueDataType {
  readonly key: string | number;
  readonly value: string | number;
}

export interface MultiRowDataType {
  readonly title: string | number;
  readonly values: {
    readonly value?: string | number;
    readonly subValue?: string | number;
  }[];
}

export interface ImagesDataType {
  readonly title: string | number;
  readonly values: {
    readonly label?: string;
    readonly image?: string;
  }[];
}

export enum EventsTreeBlockType {
  Hidden = 'hidden',
  Folder = 'folder',
  Finding = 'finding'
}

export enum EventsTreeSubitemTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Italic = 'italic'
}

export enum EventsTreeSubitemType {
  Screenshot = 'screenshot'
}

export interface EventsTreeSubitem {
  text: string;
  theme?: EventsTreeSubitemTheme;
  type?: EventsTreeSubitemType;
}

export interface EventsTreeBlockItem {
  id: number;
  type: EventsTreeBlockType;
  title: string;
  subItems?: EventsTreeSubitem[];
  children?: EventsTreeBlockItem[];
}

export interface ReportDataFacade {
  readonly reportTitle?: string | number;
  readonly revision?: KeyValueDataType;
  readonly reportDate?: KeyValueDataType;
  readonly studyDate?: KeyValueDataType;
  readonly studyId?: KeyValueDataType;
  readonly studyStart?: MultiRowDataType;
  readonly studyStop?: MultiRowDataType;
  readonly totalTimeSpan?: MultiRowDataType;
  readonly recordedTimeSpan?: MultiRowDataType;

  readonly eegType?: MultiRowDataType;
  readonly mainLogo?: string;
  readonly patientPersonalInfoTitle?: string | number;

  readonly patientName?: MultiRowDataType;
  readonly patientId?: MultiRowDataType;
  readonly gender?: MultiRowDataType;
  readonly gestationalAge?: MultiRowDataType;
  readonly correctedAge?: MultiRowDataType;
  readonly dateOfBirth?: MultiRowDataType;
  readonly age?: MultiRowDataType;
  readonly handedness?: MultiRowDataType;
  readonly postmenstrualAge?: MultiRowDataType;

  readonly studyInformationTitle?: string | number;
  readonly studyDetailsTitle?: string | number;

  readonly indicationEeg?: MultiRowDataType;
  readonly patientHistory?: MultiRowDataType;
  readonly medicationReferral?: MultiRowDataType;
  readonly diagnosisReferral?: MultiRowDataType;
  readonly episodeFrequency?: MultiRowDataType;
  readonly lastEpisode?: MultiRowDataType;
  readonly brainMRI?: MultiRowDataType;
  readonly functionalNeuroimaging?: MultiRowDataType;
  readonly scalpDefects?: MultiRowDataType;
  readonly sensorGroup?: MultiRowDataType;
  readonly alertness?: MultiRowDataType;
  readonly medicationDuringStudy?: MultiRowDataType;
  readonly medicationWithdrawal?: MultiRowDataType;
  readonly latestMeal?: MultiRowDataType;
  readonly priority?: MultiRowDataType;
  readonly workingOffHours?: MultiRowDataType;
  readonly levelOfCare?: MultiRowDataType;
  readonly studyNotes?: MultiRowDataType;
  readonly contributors?: MultiRowDataType;
  readonly diagnosisReferralMainValue?: string | number;
  readonly diagnosisReferralSubValue?: string | number;

  readonly conclusionTitle?: string | number;
  readonly findingsTitle?: string | number;

  readonly diagnosticSignificance?: MultiRowDataType;
  readonly changeSinceLastEEG?: MultiRowDataType;
  readonly etiology?: MultiRowDataType;
  readonly eegSummary?: MultiRowDataType;
  readonly clinicalComments?: MultiRowDataType;

  readonly headmodel?: string;
  readonly modulatorsTitle?: string | number;

  readonly eventsTreePayload?: EventsTreeBlockItem[];
  readonly modulatorsTreePayload?: EventsTreeBlockItem[];

  readonly isPatientHidden?: boolean;
  readonly isStudyHidden?: boolean;
  readonly isStudyDetailsHidden?: boolean;
  readonly isModulatorsHidden?: boolean;
  readonly isFindingsHidden?: boolean;
  readonly isScreenshotsHidden?: boolean;
  readonly screenshots?: ImagesDataType;
}

interface Props {
  data: ReportDataFacade;
  showCopyToclipboardOptions?: boolean;
  selectedSections?: string[];
  onCopyClipboard?: (sectionId: string, clickedOnCopy: boolean) => void;
}

const reportDataContext = React.createContext<Props>({
  data: {},
  selectedSections: []
});

const ReportDataProvider = reportDataContext.Provider;

export const useReportDataContext = () => useContext(reportDataContext);
export const useReportDataValue = <T extends keyof ReportDataFacade>(
  accessor?: T
): ReportDataFacade[T] => {
  const { data } = useReportDataContext();

  if (!accessor) {
    return;
  }

  return data[accessor];
};

export const ReportDataContainer: React.FC<Props> = ({
  children,
  data,
  showCopyToclipboardOptions,
  selectedSections,
  onCopyClipboard
}) => {
  return (
    <ReportDataProvider
      value={{
        data,
        showCopyToclipboardOptions,
        selectedSections,
        onCopyClipboard
      }}
    >
      {children}
    </ReportDataProvider>
  );
};
