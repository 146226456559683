import React from 'react';
import { Card, CardTheme } from 'components/Card';
import { Icon, IconType } from 'components/Icon';
import { useReportDataValue } from 'components/ReportDataContainer';
import { useReportDataContext } from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';
import { Tooltip } from 'components/Tooltip';

import { BaseBlock } from '../BaseBlock';

import styles from './GenericSection.module.scss';

interface Metadata {
  theme?: CardTheme;
  hideDivider?: boolean;
  uniqueSectionId?: string;
}

export class GenericSection extends BaseBlock<Metadata, boolean> {
  identifier = BlockIdentifier.GenericSection;

  Component: React.FC<Metadata> = ({
    children,
    theme = CardTheme.Transparent,
    hideDivider = false,
    uniqueSectionId = ''
  }) => {
    const { showCopyToclipboardOptions, onCopyClipboard, selectedSections } =
      useReportDataContext();
    const isHidden = useReportDataValue(this.accessor);
    const sectionSelected = selectedSections?.includes(uniqueSectionId);

    if (isHidden) {
      return null;
    }

    return (
      <div className={styles['generic-section']}>
        {showCopyToclipboardOptions && onCopyClipboard && (
          <div
            className={styles['copy-section']}
            style={{
              display: sectionSelected ? 'flex' : 'none'
            }}
          >
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>Copy this section content to clipboard</span>
                  )
                }
              ]}
            >
              <Icon
                iconType={IconType.Copy}
                onClick={() => onCopyClipboard(uniqueSectionId, true)}
              />
            </Tooltip>
            <div className={styles['round']}>
              <input
                onChange={() => {
                  onCopyClipboard(uniqueSectionId, false);
                }}
                type='checkbox'
                checked={sectionSelected}
                id={`radio-${uniqueSectionId}`}
              />
              <label htmlFor={`radio-${uniqueSectionId}`}></label>
            </div>
          </div>
        )}
        {!hideDivider && (
          <div data-testid='divider' className={styles.divider} />
        )}
        <Card theme={theme} className={styles['inner']} id={uniqueSectionId}>
          {children}
        </Card>
      </div>
    );
  };
}
