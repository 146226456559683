import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import composeRefs from '@seznam/compose-react-refs/composeRefs';
import cn from 'classnames';

import { FormInputTheme } from '../FormInput';

import styles from './TextArea.module.scss';

interface Props {
  value?: string | number;
  error?: string;
  disabled?: boolean;
  readOnly?: boolean;
  minRows?: number;
  maxRows?: number;
  className?: string;
  placeholder?: string;
  theme?: FormInputTheme;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      error,
      className,
      theme,
      value,
      readOnly,
      disabled,
      onChange,
      minRows = 1,
      maxRows = 100,
      ...inputProps
    },
    ref
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [rows, setRows] = useState<number>(minRows);

    const resizeTextArea = useCallback(
      (element: HTMLTextAreaElement | null) => {
        if (!element) return;

        const textareaLineHeight = 20;
        const previousRows = element.rows;
        element.rows = minRows;

        const currentRows = Math.floor(
          element.scrollHeight / textareaLineHeight
        );

        if (currentRows === previousRows) {
          element.rows = currentRows;
        }

        if (currentRows >= maxRows) {
          element.rows = maxRows;
          element.scrollTop = element.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows);
      },
      [maxRows, minRows]
    );

    useEffect(() => {
      resizeTextArea(textareaRef?.current);
    }, [resizeTextArea]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(event);

      resizeTextArea(textareaRef?.current);
    };

    return (
      <textarea
        {...inputProps}
        value={value}
        rows={rows}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        ref={composeRefs(textareaRef, ref)}
        className={cn(styles.textarea, theme && styles[theme], className)}
      />
    );
  }
);
