import React from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';

import styles from './NotificationButton.module.scss';

interface Props {
  count?: number;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  className?: string;
}

export const NotificationButton: React.FC<Props> = ({
  count,
  onClick,
  className
}) => (
  <button onClick={onClick} className={cn(styles.notification, className)}>
    <Icon iconType={IconType.Bell} className={styles.icon} />
    {count && (
      <div data-testid='notification-counter' className={styles.counter}>
        {count < 100 ? count : '99+'}
      </div>
    )}
  </button>
);
