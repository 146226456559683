import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Card } from 'components/Card';

import styles from './OverflowTooltipCell.module.scss';

interface Props {
  className?: string;
}

export const OverflowTooltipCell: React.FC<Props> = ({
  children,
  className,
  ...rest
}) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref?.current?.childElementCount
      ? (ref?.current?.firstChild as HTMLElement)
      : ref?.current;
    // TODO: Jest DOM does not support testing of this behavior
    // https://github.com/jsdom/jsdom/issues/135
    if (element) {
      const hasOverflowingChildren =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;

      if (isOverflowed !== hasOverflowingChildren) {
        setIsOverflowed(hasOverflowingChildren);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.offsetWidth, ref.current?.scrollWidth]);

  return (
    <div {...rest} className={cn(styles['overflow-tooltip-cell'], className)}>
      <div ref={ref} className={styles['cell-child']}>
        {children}
      </div>
      {isOverflowed && <Card className={styles.tooltip}>{children}</Card>}
    </div>
  );
};
