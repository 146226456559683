import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './RadioButton.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  isFocused?: boolean;
  className?: string;
}

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ label, isFocused, className, ...inputProps }, ref) => (
    <label
      className={cn(
        styles['radio-button'],
        inputProps?.disabled && styles['is-disabled'],
        isFocused && styles['is-focused'],
        className
      )}
    >
      <input ref={ref} {...inputProps} type='radio' className={styles.radio} />
      <span className={styles.label}>{label}</span>
    </label>
  )
);
