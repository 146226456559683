import React, { ReactElement } from 'react';
import { processNodes } from 'react-html-parser';
import convertInlineStyle from 'react-html-parser/lib/utils/inlineStyleToObject';
import { DomElement } from 'htmlparser2';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export interface DomNode {
  type: 'tag' | 'style' | 'text' | 'directive' | 'comment' | 'root';
  name: string;
  children: DomNode[];
  attribs: { [name: string]: string };
  parent: DomNode;
  data?: string;
}

interface Transform {
  (
    node: DomElement,
    index: number,
    transform?: Transform
  ): ReactElement | void | null;
}

export interface Transformer {
  transform: Transform;
  sanitize(html: string): string;
}

export class TransformerDecorator implements Transformer {
  constructor(protected transformer: Transformer) {}

  transform(node: DomNode, index: number, transform?: Transform) {
    return this.transformer.transform(node, index, transform);
  }
  sanitize(html: string) {
    return this.transformer.sanitize(html);
  }
}

export class ReactTransformer implements Transformer {
  protected allowedTags: IOptions['allowedTags'] =
    sanitizeHtml.defaults.allowedTags.concat([
      'svg',
      'style',
      'defs',
      'clippath',
      'ellipse',
      'pattern',
      'rect',
      'g',
      'path',
      'circle',
      'text',
      'tspan',
      'template'
    ]);
  protected allowedAttributes: IOptions['allowedAttributes'] = {
    ...sanitizeHtml.defaults.allowedAttributes,
    '*': [
      'id',
      'style',
      'width',
      'height',
      'class',
      'data-*',
      'transform',
      'dx',
      'dy'
    ],
    svg: ['viewbox'],
    style: ['type'],
    ellipse: ['ry', 'rx', 'cy', 'cx'],
    pattern: ['patterntransform', 'patternunits', 'xlink:href'],
    rect: ['y', 'x'],
    g: ['clip-path'],
    path: ['d'],
    circle: ['cx', 'cy', 'r'],
    text: ['x', 'y'],
    tspan: ['x', 'y']
  };
  protected selfClosing: IOptions['selfClosing'] =
    sanitizeHtml.defaults.selfClosing.concat([
      'path',
      'ellipse',
      'rect',
      'circle'
    ]);

  transform(node: DomNode, index: number, transformParam?: Transform) {
    const transform = transformParam || this.transform.bind(this);
    if (node.type === 'tag' && node.name === 'svg') {
      const { viewbox, ...props } = node.attribs;
      return (
        <svg key={index} viewBox={viewbox} {...props}>
          {processNodes(node.children, transform)}
        </svg>
      );
    }

    if (node.type === 'tag' && node.name === 'clippath') {
      const { class: className, ...props } = node.attribs;
      return (
        <clipPath key={index} className={className} {...props}>
          {processNodes(node.children, transform)}
        </clipPath>
      );
    }

    if (node.type === 'tag' && node.name === 'pattern') {
      const { patterntransform, patternunits, ...props } = node.attribs;
      const xlinkHref = props['xlink:href'];

      delete props['xlink:href'];

      return (
        <pattern
          key={index}
          patternTransform={patterntransform}
          patternUnits={patternunits}
          xlinkHref={xlinkHref}
          {...props}
        >
          {processNodes(node.children, transform)}
        </pattern>
      );
    }

    if (node.type === 'tag' && node.name === 'ellipse') {
      const { class: className, ...props } = node.attribs;
      return (
        <ellipse key={index} className={className} {...props}>
          {processNodes(node.children, transform)}
        </ellipse>
      );
    }

    if (node.type === 'tag' && node.name === 'g') {
      const { style, class: className, ...props } = node.attribs;
      const clipPath = props['clip-path'];
      delete props['clip-path'];
      return (
        <g
          key={index}
          className={className}
          clipPath={clipPath}
          style={convertInlineStyle(style)}
          {...props}
        >
          {processNodes(node.children, transform)}
        </g>
      );
    }

    return undefined;
  }

  sanitize(html: string) {
    return sanitizeHtml(html, {
      allowedTags: this.allowedTags,
      allowedAttributes: this.allowedAttributes,
      selfClosing: this.selfClosing
    });
  }
}
