import React from 'react';
import cn from 'classnames';

import styles from './MiniHeadModel.module.scss';

interface Props {
  className?: string;
  quarters: [boolean, boolean, boolean, boolean];
}

export const MiniHeadModel: React.FC<Props> = ({ quarters, className }) => (
  <div className={cn(styles.head, className)}>
    {quarters.map((quarter: boolean, index: number) => (
      <span
        key={index}
        data-testid={quarter ? 'quarter-active' : 'quarter-inactive'}
        className={cn(styles.quarter, quarter && styles.active)}
      />
    ))}
  </div>
);
