import cn from 'classnames';

import {
  AlignAnySizes,
  AlignContentSizes,
  AlignItemsSizes,
  AlignSelfSizes,
  Breakpoint,
  Justify,
  JustifySizes
} from './types';

import './flex.scss';

const makeJustifyClassName = (justify: Justify, breakpoint?: Breakpoint) => {
  if (breakpoint) {
    return `justify-content-${breakpoint}-${justify}`;
  }

  return `justify-content-${justify}`;
};

const makeAlignClassName = <
  T extends AlignContentSizes | AlignItemsSizes | AlignSelfSizes
>({
  type,
  direction,
  size
}: T) => {
  if (size) {
    return `align-${type}-${size}-${direction}`;
  }

  return `align-${type}-${direction}`;
};

export const applyMaybeArray = <T>(
  value?: T | T[],
  callback?: (value: T) => string
) => {
  if (!value || !callback) {
    return;
  }

  if (Array.isArray(value)) {
    return cn(value.map(callback));
  }

  return callback(value);
};

export const makeJustifyStyles = (justify?: JustifySizes | JustifySizes[]) => {
  return applyMaybeArray(justify, ({ direction, size }) =>
    makeJustifyClassName(direction, size)
  );
};

export const makeAlignStyles = <A extends AlignAnySizes>(align?: A | A[]) => {
  return applyMaybeArray(align, makeAlignClassName);
};
