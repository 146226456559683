import React, { ComponentProps, ElementType } from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';

import styles from './SectionNavTab.module.scss';

interface Props<T> {
  component: T;
  label: string;
  className?: string;
  active?: boolean;
  icon?: IconType;
}

export const SectionNavTab = <T extends ElementType>({
  component,
  className,
  label,
  icon,
  children,
  active,
  ...rest
}: Props<T> & ComponentProps<T>) => {
  const SectionTabComponent = component;

  return (
    <SectionTabComponent
      {...rest}
      className={cn(
        styles['section-nav-tab'],
        active && styles.active,
        className
      )}
    >
      <div className={styles['content']}>
        {icon && (
          <Icon
            iconType={icon}
            data-testid='main-icon'
            className={styles['main-icon']}
          />
        )}
        <span>{label}</span>
      </div>
      {children ? (
        children
      ) : (
        <Icon
          data-testid='chevron-icon'
          iconType={IconType.ChevronDown}
          className={styles['chevron-icon']}
        />
      )}
    </SectionTabComponent>
  );
};

SectionNavTab.activeClassName = styles.active;
