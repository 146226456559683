import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'components/Icon';

import styles from './Input.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  inputClassName?: string;
  icon?: IconType;
  cancelable?: boolean;
  type?: 'text' | 'password';
  iconClassName?: string;
}

export const Input: React.FC<Props> = ({
  className,
  inputClassName,
  icon,
  cancelable,
  value,
  disabled,
  type = 'text',
  iconClassName,
  ...inputProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onCancelClick = useCallback(() => {
    if (inputRef?.current) {
      const input = inputRef.current;
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window?.HTMLInputElement.prototype,
        'value'
      )?.set;

      nativeInputValueSetter?.call(input, '');

      const inputEvent = new Event('input', { bubbles: true });
      input.dispatchEvent(inputEvent);
    }
  }, []);

  const canCancel = cancelable && value !== '' && !disabled;

  return (
    <div className={cn(styles.container, className)}>
      <input
        {...inputProps}
        type={type}
        value={value}
        disabled={disabled}
        className={cn(
          styles.input,
          icon && styles.iconed,
          cancelable && styles.cancelable,
          inputClassName
        )}
        ref={inputRef}
      />
      {icon && (
        <Icon
          data-testid='icon'
          className={cn(styles.icon, iconClassName)}
          iconType={icon}
        />
      )}
      {canCancel && (
        <button onClick={onCancelClick} className={styles['cancel-icon']}>
          <Icon iconType={IconType.Close} />
        </button>
      )}
    </div>
  );
};
