import React from 'react';
import cn from 'classnames';

import colors from './Text.colors.module.scss';
import styles from './Text.module.scss';
import themes from './Text.themes.module.scss';

export type TextThemes = keyof typeof themes;
export type TextColors = keyof typeof colors;

interface Props {
  className?: string;
  theme?: keyof typeof themes;
  color?: keyof typeof colors;
  italic?: boolean;
  uppercase?: boolean;
}

export const Text: React.FC<Props> = ({
  className,
  theme = 'text-regular',
  color,
  italic,
  uppercase,
  children
}) => (
  <span
    className={cn(
      styles.text,
      italic && styles.italic,
      themes[theme],
      color && colors[color],
      uppercase && styles.uppercase,
      className
    )}
  >
    {children}
  </span>
);
