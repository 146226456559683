import React from 'react';
import holbergEegLogo from 'assets/holberg-eeg.png';
import hiScoreLogo from 'assets/logo.png';
import cn from 'classnames';

import styles from './LoadingOverlay.module.scss';

interface Props {
  className?: string;
  isSplashScreen?: boolean;
  loading: boolean;
}

export const LoadingOverlay: React.FC<Props> = ({
  loading,
  isSplashScreen,
  children,
  className
}) => {
  return (
    <div className={cn(styles['loading-overlay'], className)}>
      <div
        data-testid='overlay'
        className={cn(
          styles.overlay,
          loading && styles.disabled,
          isSplashScreen && styles.noscoll
        )}
      >
        {children}
      </div>
      {loading && (
        <div className={cn(isSplashScreen && styles['splash-screen'])}>
          {isSplashScreen && (
            <img src={hiScoreLogo} alt='hiScore' className={styles.logo} />
          )}
          <div data-testid='spinner' className={styles.spinner} />
          {isSplashScreen && (
            <div className={styles.company}>
              <img src={holbergEegLogo} alt='holberg eeg' />
              <span>Uniting Medical Data</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
