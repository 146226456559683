import React from 'react';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

import styles from './IndivisibleBlock.module.scss';

export class IndivisibleBlock extends BaseBlock {
  identifier = BlockIdentifier.IndivisibleBlock;

  Component: React.FC = ({ children }) => {
    return <div className={styles['indivisible-block']}>{children}</div>;
  };
}
