import React, { ReactNode } from 'react';
import cn from 'classnames';

import { RegionTag, Tag } from './RegionTag';

import styles from './RegionTags.module.scss';

interface Props {
  placeholder: string;
  placeholderVisible?: boolean;
  className?: string;
  onRemoveTag?: (id: Tag['id']) => void;
  tags: Tag[];
  renderTag?: (
    tagProps: Tag & { onRemoveTag?: Props['onRemoveTag'] }
  ) => ReactNode;
}

export const RegionTags: React.FC<Props> = ({
  placeholder,
  placeholderVisible,
  tags,
  onRemoveTag,
  renderTag,
  className
}) => (
  <div className={cn(styles['region-tags'], className)}>
    {(!tags.length || placeholderVisible) && (
      <span className={styles.placeholder}>{placeholder}</span>
    )}

    {tags.map((props) =>
      renderTag ? (
        renderTag({ ...props, onRemoveTag })
      ) : (
        <RegionTag key={props.id} {...props} onRemoveTag={onRemoveTag} />
      )
    )}
  </div>
);
