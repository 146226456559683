import { CardTheme } from 'components/Card';

import { Justify } from '../Grid/types';
import { BlockFragment } from './blocks/BlockFragment';
import { Col } from './blocks/Col';
import { EventsTreeBlock } from './blocks/EventsTreeBlock';
import { Float } from './blocks/Float/Float';
import { GenericSection } from './blocks/GenericSection';
import { HeadModel } from './blocks/HeadModel';
import { Images } from './blocks/Images';
import { IndivisibleBlock } from './blocks/IndivisibleBlock';
import { Logotype } from './blocks/Logotype';
import { MultiRowProperty } from './blocks/MultiRowProperty';
import { Padding } from './blocks/Padding';
import { Row } from './blocks/Row';
import { Title } from './blocks/Title';

export const defaultTemplate = new BlockFragment({
  items: [
    new Padding({
      metadata: {
        right: 32,
        left: 32
      },
      items: [
        new Row({
          items: [
            new Col({
              items: [
                new Logotype({
                  accessor: 'mainLogo'
                })
              ],
              metadata: {
                size: 'auto'
              }
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        hideDivider: true,
        uniqueSectionId: 'patient-details-section'
      },
      accessor: 'isPatientHidden',
      items: [
        new Row({
          items: [
            new Col({
              items: [
                new Title({
                  accessor: 'patientPersonalInfoTitle',
                  metadata: {
                    theme: 'main-semibold',
                    color: 'N1000'
                  }
                })
              ]
            })
          ]
        }),
        new Padding({
          metadata: {
            top: 16
          },
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'patientName'
            })
          ]
        }),
        new MultiRowProperty({
          metadata: {
            title: {
              size: 3
            },
            value: {
              size: 9
            }
          },
          accessor: 'patientId'
        }),

        new Row({
          items: [
            new Col({
              items: [
                new MultiRowProperty({
                  accessor: 'dateOfBirth'
                })
              ],
              metadata: {
                size: 6
              }
            }),
            new Col({
              items: [
                new MultiRowProperty({
                  accessor: 'gender'
                })
              ],
              metadata: {
                size: 6
              }
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        uniqueSectionId: 'report-details-section'
      },
      accessor: 'isStudyHidden',
      items: [
        new Row({
          metadata: {
            justify: {
              direction: Justify.between
            }
          },
          items: [
            new Title({
              accessor: 'studyInformationTitle',
              metadata: {
                color: 'N1000',
                theme: 'main-semibold'
              }
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new Row({
              items: [
                new Col({
                  items: [
                    new MultiRowProperty({
                      metadata: {
                        title: {
                          size: 6
                        },
                        value: {
                          size: 6
                        }
                      },
                      accessor: 'studyStart'
                    })
                  ],
                  metadata: {
                    size: 6
                  }
                }),
                new Col({
                  items: [
                    new MultiRowProperty({
                      metadata: {
                        title: {
                          size: 6
                        },
                        value: {
                          size: 6
                        }
                      },
                      accessor: 'totalTimeSpan'
                    })
                  ],
                  metadata: {
                    size: 6
                  }
                })
              ]
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new Row({
              items: [
                new Col({
                  items: [
                    new MultiRowProperty({
                      metadata: {
                        title: {
                          size: 6
                        },
                        value: {
                          size: 6
                        }
                      },
                      accessor: 'studyStop'
                    })
                  ],
                  metadata: {
                    size: 6
                  }
                }),
                new Col({
                  items: [
                    new MultiRowProperty({
                      metadata: {
                        title: {
                          size: 6
                        },
                        value: {
                          size: 6
                        }
                      },
                      accessor: 'recordedTimeSpan'
                    })
                  ],
                  metadata: {
                    size: 6
                  }
                })
              ]
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'eegType'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'indicationEeg'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'patientHistory'
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        theme: CardTheme.Form,
        uniqueSectionId: 'conclusion-details-section'
      },
      items: [
        new IndivisibleBlock({
          items: [
            new Float({
              items: [
                new HeadModel({
                  accessor: 'headmodel'
                })
              ],
              metadata: {
                float: 'right',
                width: '40%'
              }
            }),
            new Row({
              items: [
                new Col({
                  metadata: {
                    size: 12
                  },
                  items: [
                    new Title({
                      accessor: 'conclusionTitle',
                      metadata: {
                        theme: 'main-semibold',
                        color: 'N1000'
                      }
                    })
                  ]
                })
              ]
            }),
            new Padding({
              metadata: {
                top: 16
              },
              items: [
                new IndivisibleBlock({
                  items: [
                    new MultiRowProperty({
                      metadata: {
                        textWrapEnabled: true,
                        title: {
                          size: 12,
                          uppercase: true,
                          theme: 'label'
                        },
                        value: {
                          size: 12,
                          padded: true
                        }
                      },
                      accessor: 'diagnosticSignificance'
                    })
                  ]
                }),
                new Padding({
                  metadata: {
                    top: 16
                  },
                  items: [
                    new IndivisibleBlock({
                      items: [
                        new MultiRowProperty({
                          metadata: {
                            textWrapEnabled: true,
                            title: {
                              size: 12,
                              uppercase: true,
                              theme: 'label'
                            },
                            value: {
                              size: 12,
                              padded: true
                            }
                          },
                          accessor: 'changeSinceLastEEG'
                        })
                      ]
                    })
                  ]
                }),
                new Padding({
                  metadata: {
                    top: 16
                  },
                  items: [
                    new IndivisibleBlock({
                      items: [
                        new MultiRowProperty({
                          metadata: {
                            textWrapEnabled: true,
                            title: {
                              size: 12,
                              uppercase: true,
                              theme: 'label'
                            },
                            value: {
                              size: 12,
                              padded: true
                            }
                          },
                          accessor: 'etiology'
                        })
                      ]
                    })
                  ]
                }),
                new Padding({
                  metadata: {
                    top: 16
                  },
                  items: [
                    new IndivisibleBlock({
                      items: [
                        new MultiRowProperty({
                          metadata: {
                            textWrapEnabled: true,
                            title: {
                              size: 12,
                              uppercase: true,
                              theme: 'label'
                            },
                            value: {
                              size: 12,
                              padded: true
                            }
                          },
                          accessor: 'eegSummary'
                        })
                      ]
                    })
                  ]
                }),
                new Padding({
                  metadata: {
                    top: 16
                  },
                  items: [
                    new IndivisibleBlock({
                      items: [
                        new MultiRowProperty({
                          metadata: {
                            textWrapEnabled: true,
                            title: {
                              size: 12,
                              uppercase: true,
                              theme: 'label'
                            },
                            value: {
                              size: 12,
                              padded: true
                            }
                          },
                          accessor: 'clinicalComments'
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            new Float({
              metadata: {
                clear: true
              }
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        uniqueSectionId: 'study-details-section'
      },
      accessor: 'isStudyDetailsHidden',
      items: [
        new Row({
          items: [
            new Col({
              metadata: {
                size: 12
              },
              items: [
                new Title({
                  accessor: 'studyDetailsTitle',
                  metadata: {
                    theme: 'main-semibold',
                    color: 'N1000'
                  }
                })
              ]
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'diagnosisReferral'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'episodeFrequency'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'lastEpisode'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'brainMRI'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'scalpDefects'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'alertness'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'medicationReferral'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'medicationDuringStudy'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'medicationWithdrawal'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'latestMeal'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'priority'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'workingOffHours'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'levelOfCare'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'studyNotes'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'functionalNeuroimaging'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'sensorGroup'
            })
          ]
        }),
        new IndivisibleBlock({
          items: [
            new MultiRowProperty({
              metadata: {
                title: {
                  size: 3
                },
                value: {
                  size: 9
                }
              },
              accessor: 'contributors'
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        uniqueSectionId: 'modulators-section'
      },
      accessor: 'isModulatorsHidden',
      items: [
        new Row({
          items: [
            new Col({
              items: [
                new Title({
                  accessor: 'modulatorsTitle',
                  metadata: {
                    theme: 'main-semibold',
                    color: 'N1000'
                  }
                })
              ]
            })
          ]
        }),
        new Row({
          items: [
            new Col({
              items: [
                new Padding({
                  metadata: {
                    left: 16
                  },
                  items: [
                    new EventsTreeBlock({
                      accessor: 'modulatorsTreePayload'
                    })
                  ]
                })
              ]
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        uniqueSectionId: 'findings-section'
      },
      accessor: 'isFindingsHidden',
      items: [
        new Row({
          items: [
            new Col({
              items: [
                new Title({
                  accessor: 'findingsTitle',
                  metadata: {
                    theme: 'main-semibold',
                    color: 'N1000'
                  }
                })
              ]
            })
          ]
        }),
        new Row({
          items: [
            new Col({
              items: [
                new EventsTreeBlock({
                  accessor: 'eventsTreePayload'
                })
              ]
            })
          ]
        })
      ]
    }),
    new GenericSection({
      metadata: {
        hideDivider: false
      },
      accessor: 'isScreenshotsHidden',
      items: [
        new Images({
          accessor: 'screenshots'
        })
      ]
    })
  ]
});
