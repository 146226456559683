import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Column,
  SortingRule,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  useTable
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import cn from 'classnames';
import { OverflowTooltipCell } from 'components/OverflowTooltipCell';
import { useDebouncedValue } from 'helpers/useDebouncedValue';
import { isHTML } from 'helpers/validators';
import { useCustomTranslation } from 'hooks/translation';

import { GeneralError } from '../GeneralError';
import { IconType } from '../Icon';
import { TableFooter } from '../TableFooter';
import { TableHeader } from '../TableHeader';
import { withColumnsWidth } from './helpers';
import {
  ColumnStickyState,
  TableMessages,
  TableMessagesTitles
} from './Table.enum';

import styles from './Table.module.scss';

export interface TableColumnProps<D extends object = {}> {
  id: keyof D;
  order: number;
  isVisible: boolean;
  sticky?: ColumnStickyState;
  isSortedDesc?: boolean;
  isSorted?: boolean;
}

export type TableColumn<D extends object = {}> = TableColumnProps<D> &
  Column<D>;

export interface TableProps<T extends object> {
  columns: Array<TableColumn<T>>;
  data: Array<T>;
  className?: string;
  bodyClassName?: string;
  disabled?: boolean;
  footer?: ReactNode;
  emptyStateMessage?: TableMessages;
  errorMessage?: TableMessages;
  overflowed?: boolean;
  tableProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  onWidthChange?: (widths: Record<string, number>) => void;
  onRowClick?: (rowItem: T) => void;
  onSortChange?: (sortBy: Array<SortingRule<T>>) => void;
}

export const Table: <T extends object>(
  p: TableProps<T>
) => React.ReactElement<TableProps<T>> = ({
  columns,
  data,
  className,
  bodyClassName,
  tableProps,
  footer,
  disabled,
  onSortChange,
  overflowed,
  onWidthChange,
  onRowClick,
  emptyStateMessage = TableMessages.AllHidden,
  errorMessage
}) => {
  const [hasPendingSortCallback, setHasPendingSortCallback] = useState(false);

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    totalColumnsWidth,
    getTableBodyProps,
    state
  } = useTable(
    {
      autoResetSortBy: false,
      manualSortBy: true,
      data: useMemo(() => data, [data]),
      columns: useMemo(() => withColumnsWidth(columns), [columns]),
      initialState: {
        sortBy: columns
          .filter((column) => column.isSorted)
          .map(({ id, isSortedDesc }) => ({ id, desc: isSortedDesc })),
        hiddenColumns: columns
          .filter((column) => !column.isVisible)
          .map(({ accessor, id }) => {
            return typeof accessor === 'function' ? id! : String(accessor)!;
          })
      }
    },
    useBlockLayout,
    useSortBy,
    useSticky,
    useResizeColumns
  );

  const debouncedState = useDebouncedValue(state, 500);
  const SVGRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const renderSVGLeftSide =
    rowRef.current &&
    SVGRef.current &&
    tableRef.current &&
    rowRef.current.offsetWidth -
      (SVGRef.current.getBoundingClientRect().left +
        tableRef.current.scrollLeft) <
      200;

  useEffect(() => {
    if (hasPendingSortCallback) {
      onSortChange && onSortChange(state.sortBy);
      setHasPendingSortCallback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSortChange, state.sortBy]);

  useEffect(() => {
    if (!debouncedState.columnResizing.isResizingColumn) {
      onWidthChange &&
        onWidthChange(debouncedState.columnResizing.columnWidths);
    }
  }, [
    debouncedState.columnResizing.columnWidths,
    debouncedState.columnResizing.isResizingColumn,
    onWidthChange
  ]);

  const { t } = useCustomTranslation();
  const isAllHidden = useMemo(
    () => columns.every(({ isVisible }) => !isVisible),
    [columns]
  );

  return (
    <div className={cn(styles.table, className, disabled && styles.disabled)}>
      {!isAllHidden &&
        (!!errorMessage ? (
          <GeneralError
            title={t(TableMessagesTitles[errorMessage])}
            className={styles.error}
          />
        ) : (
          !rows.length && (
            <GeneralError
              icon={IconType.Eye}
              className={cn(styles.empty, styles.error)}
              title={t(TableMessagesTitles[emptyStateMessage])}
            />
          )
        ))}
      <div
        {...getTableProps()}
        {...tableProps}
        className={cn(styles['table-main'], bodyClassName)}
        ref={tableRef}
      >
        {isAllHidden ? (
          <GeneralError
            icon={IconType.Empty}
            title={t(TableMessagesTitles[TableMessages.AllHidden])}
          />
        ) : (
          <Fragment>
            <TableHeader
              overflowed={overflowed}
              onSortClick={() => {
                setHasPendingSortCallback(true);
              }}
              resizable={!!onWidthChange}
              headerGroups={headerGroups}
              totalColumnsWidth={totalColumnsWidth}
            />
            {!errorMessage && !!rows.length && (
              <div {...getTableBodyProps()} className={styles.body}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <div
                      {...row.getRowProps()}
                      onClick={() => onRowClick && onRowClick(row.original)}
                      className={styles.tr}
                      data-testid='tr'
                      ref={rowRef}
                    >
                      {row.cells.map((cell) => {
                        const Container = overflowed
                          ? 'div'
                          : OverflowTooltipCell;

                        return (
                          <Container
                            {...cell.getCellProps()}
                            data-testid='td'
                            style={{
                              ...cell.getCellProps().style,
                              display: 'flex'
                            }}
                            className={cn({
                              [styles.td]: true,
                              [styles.overflowed]: overflowed,
                              [styles.isHTML]: isHTML(cell.value)
                            })}
                          >
                            {isHTML(cell.value) ? (
                              <div
                                className={cn({
                                  [styles['HTML-container']]: true,
                                  [styles.left]: renderSVGLeftSide
                                })}
                                ref={SVGRef}
                                dangerouslySetInnerHTML={{
                                  __html: cell.value
                                }}
                              />
                            ) : (
                              cell.render('Cell')
                            )}
                          </Container>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </Fragment>
        )}
      </div>
      {footer && <TableFooter>{footer}</TableFooter>}
    </div>
  );
};
