import React from 'react';
import cn from 'classnames';

import { useContainerSize } from '../grid-mapping';
import { Breakpoint } from '../types';

interface PropsBase {
  className?: string;
}

interface PropsFluid extends PropsBase {
  fluid?: boolean;
  size?: never;
}

interface PropsSized extends PropsBase {
  fluid?: never;
  size?: Breakpoint;
}

export const Container: React.FC<PropsFluid | PropsSized> = ({
  className,
  fluid,
  children,
  size: breakpoint
}) => {
  const size = useContainerSize(breakpoint, fluid);

  return <div className={cn(size, className)}>{children}</div>;
};
