import { ReportDataFacade } from 'components/ReportDataContainer/ReportDataContainer';

import { BlockIdentifier } from './BlockIdentifier.enum';
import { BaseBlock } from './blocks/BaseBlock';
import { BlockFragment } from './blocks/BlockFragment';
import { Col } from './blocks/Col';
import { EventsTreeBlock } from './blocks/EventsTreeBlock';
import { Float } from './blocks/Float';
import { GenericSection } from './blocks/GenericSection';
import { HeadModel } from './blocks/HeadModel';
import { Images } from './blocks/Images';
import { IndivisibleBlock } from './blocks/IndivisibleBlock';
import { KeyValue } from './blocks/KeyValue';
import { Logotype } from './blocks/Logotype';
import { MultiRowProperty } from './blocks/MultiRowProperty';
import { Padding } from './blocks/Padding';
import { RootContainer } from './blocks/RootContainer';
import { Row } from './blocks/Row';
import { Title } from './blocks/Title';

export class SchemaDeserializationError extends Error {
  constructor(identifier: BlockIdentifier) {
    super(
      `Block with identifier: "${identifier}" does not have a concrete implementation`
    );
    this.name = 'SchemaDeserializationError';
  }
}

const mapping = {
  [BlockIdentifier.RootContainer]: RootContainer,
  [BlockIdentifier.GenericSection]: GenericSection,
  [BlockIdentifier.Row]: Row,
  [BlockIdentifier.Col]: Col,
  [BlockIdentifier.Title]: Title,
  [BlockIdentifier.Logotype]: Logotype,
  [BlockIdentifier.KeyValue]: KeyValue,
  [BlockIdentifier.Padding]: Padding,
  [BlockIdentifier.HeadModel]: HeadModel,
  [BlockIdentifier.MultiRowProperty]: MultiRowProperty,
  [BlockIdentifier.BlockFragment]: BlockFragment,
  [BlockIdentifier.EventsTreeBlock]: EventsTreeBlock,
  [BlockIdentifier.Float]: Float,
  [BlockIdentifier.IndivisibleBlock]: IndivisibleBlock,
  [BlockIdentifier.Images]: Images
};

export const getConstructorForIdentifier = (identifier: BlockIdentifier) => {
  const block = mapping[identifier];

  if (!block) {
    return null;
  }

  return block;
};

interface PlainBlock extends BaseBlock<{}, keyof ReportDataFacade> {
  Component: never;
  items: PlainBlock[];
}

export const deserializeReportSchema = (plainSchema: PlainBlock): BaseBlock => {
  const iterate = (block: PlainBlock): BaseBlock => {
    const ConcreteBlock = getConstructorForIdentifier(block.identifier);

    if (!ConcreteBlock) {
      throw new SchemaDeserializationError(block.identifier);
    }

    return new ConcreteBlock({
      accessor: block.accessor,
      metadata: block.metadata,
      items: block.items.map(iterate)
    });
  };

  return iterate(plainSchema);
};
