import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

import { Card } from '../Card';

import styles from './Popup.module.scss';

interface Props {
  exceptQuery?: string;
  onOutsideClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  visible: boolean;
  className?: string;
}

export const Popup: React.FC<Props> = ({
  visible,
  onOutsideClick = () => {},
  exceptQuery,
  className,
  children
}) => (
  <div
    data-testid='popup'
    className={cn(styles.popup, visible && styles.visible, className)}
  >
    <OutsideClickHandler
      onOutsideClick={(e) => {
        if (visible) {
          const matchesQuery =
            // @ts-ignore
            typeof e.target.matches === 'function' && exceptQuery
              ? // @ts-ignore
                e.target.matches(exceptQuery)
              : false;

          !matchesQuery && onOutsideClick(e);
        }
      }}
    >
      <Card>{children}</Card>
    </OutsideClickHandler>
  </div>
);
