import { ErrorTitles } from 'components/ErrorMessage';

export enum ColumnStickyState {
  Left = 'left',
  Right = 'right'
}

export enum TableMessages {
  AllHidden = 'all_hidden',
  NoMatchedData = 'no_matched_data',
  NoData = 'no_data',
  LoadError = 'load_error'
}

export const TableMessagesTitles = Object.freeze<Record<TableMessages, string>>(
  {
    [TableMessages.AllHidden]:
      'Unhide at least one column to start seeing records again',
    [TableMessages.NoMatchedData]: 'No records match your current filters',
    [TableMessages.NoData]:
      'This list looks empty, please import records first',
    [TableMessages.LoadError]: ErrorTitles.Load
  }
);
