import React from 'react';
import cn from 'classnames';

import styles from './Ladder.module.scss';

interface Props {
  className?: string;
}

export const Ladder: React.FC<Props> = ({ className, children }) => (
  <div className={cn(styles.container, className)}>{children}</div>
);
