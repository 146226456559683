import React from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from '../Button';
import { Modal } from '../Modal';
import { ModalProps } from '../Modal/Modal';

import styles from './ConfirmationModal.module.scss';

interface Props extends ModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  cancelButtonTitle: string;
  submitButtonTitle: string;
}

export const ConfirmationModal: React.FC<Props> = React.memo(
  ({
    children,
    className,
    onCancel,
    onSubmit,
    cancelButtonTitle,
    headerClassName,
    submitButtonTitle,
    contentClassName,
    ...props
  }) => (
    <Modal
      {...props}
      headerClassName={cn(styles.header, headerClassName)}
      className={cn(styles['confirmation-modal'], className)}
      contentClassName={cn(styles.content, contentClassName)}
    >
      {children}
      <div className={styles.footer}>
        <Button
          onClick={onCancel}
          title={cancelButtonTitle}
          size={ButtonSize.Regular}
          theme={ButtonTheme.SecondaryTransparent}
          className={styles['cancel-button']}
        />
        <Button
          onClick={onSubmit}
          title={submitButtonTitle}
          size={ButtonSize.Regular}
          theme={ButtonTheme.Primary}
        />
      </div>
    </Modal>
  )
);
