import React from 'react';
import { ImagesDataType } from 'components/ReportDataContainer/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';
import { Text } from 'components/Text';

import { useReportDataValue } from '../../../ReportDataContainer';
import { BaseBlock } from '../BaseBlock';

import styles from './Images.module.scss';

interface Metadata {}

export class Images extends BaseBlock<Metadata, ImagesDataType> {
  identifier = BlockIdentifier.Images;

  Component: React.FC = () => {
    const data = useReportDataValue(this.accessor);

    if (!data) {
      return null;
    }

    return (
      <div>
        {data.values.map((value, index) => {
          return (
            <div className={styles['label-image-div']} key={index}>
              {index === 0 && (
                <p>
                  <Text theme='main-semibold' color='N1000'>
                    {data.title}
                  </Text>
                </p>
              )}
              <Text theme='text-regular' color='N1000'>
                {value.label}
              </Text>
              <div>
                <img
                  src={`data:image/png;base64,${value.image}`}
                  alt={value.label}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
}
