import React, { Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { TooltipProps } from 'react-tooltip';
import cn from 'classnames';

import { TooltipIds } from './Tooltip.enum';

import styles from './Tooltip.module.scss';

interface TooltipData {
  mainTooltip: string | JSX.Element;
  subTooltip?: string | JSX.Element;
}

interface Props {
  tooltipId?: string;
  data: TooltipData[];
  contentClassName?: string;
  offset?: TooltipProps['offset'];
  delayTime?: number;
}

export const Tooltip: React.FC<Props> = ({
  children,
  tooltipId = TooltipIds.DefaultPrimary,
  contentClassName,
  data,
  offset,
  delayTime = 1000
}) =>
  React.isValidElement(children)
    ? React.cloneElement(children, {
        'data-offset': offset && JSON.stringify(offset),
        'data-for': tooltipId,
        'data-delay-show': delayTime,
        'data-tip': ReactDOMServer.renderToString(
          <Fragment>
            {data.map(({ mainTooltip, subTooltip }, idx) => (
              <div
                key={idx}
                className={cn(styles.container, contentClassName)}
                data-testid='tooltip'
              >
                <div className={styles.content}>{mainTooltip}</div>
                {subTooltip && (
                  <div className={styles['sub-content']}>{subTooltip}</div>
                )}
              </div>
            ))}
          </Fragment>
        )
      })
    : null;
