import React from 'react';
import cn from 'classnames';

import { makeAlignStyles, makeJustifyStyles } from '../flex-mapping';
import { useRowColsSize, useRowPlainStyles } from '../grid-mapping';
import {
  AlignContentSizes,
  AlignItemsSizes,
  AlignSelfSizes,
  Breakpoint,
  JustifySizes,
  RowSizes
} from '../types';

import styles from './Row.module.scss';

interface Props extends RowSizes {
  className?: string;
  gutters?: boolean;
  justify?: JustifySizes | JustifySizes[];
  alignContent?: AlignContentSizes | AlignContentSizes[];
  alignItems?: AlignItemsSizes | AlignItemsSizes[];
  alignSelf?: AlignSelfSizes | AlignSelfSizes[];
}

export const Row: React.FC<Props> = ({
  className,
  gutters = true,
  children,
  xl,
  sm,
  lg,
  md,
  justify,
  alignContent,
  alignItems,
  alignSelf
}) => {
  const small = useRowColsSize(Breakpoint.sm, sm);
  const medium = useRowColsSize(Breakpoint.md, md);
  const large = useRowColsSize(Breakpoint.lg, lg);
  const xlarge = useRowColsSize(Breakpoint.xl, xl);
  const plain = useRowPlainStyles(gutters);
  const justifyStyles = makeJustifyStyles(justify);
  const alignContentStyles = makeAlignStyles(alignContent);
  const alignItemsStyles = makeAlignStyles(alignItems);
  const alignSelfStyles = makeAlignStyles(alignSelf);

  return (
    <div
      className={cn(
        plain,
        justifyStyles,
        alignContentStyles,
        alignItemsStyles,
        alignSelfStyles,
        small,
        medium,
        large,
        xlarge,
        styles.row,
        className
      )}
      data-testid='row'
    >
      {children}
    </div>
  );
};
