import React from 'react';
import cn from 'classnames';

import { makeAlignStyles } from '../flex-mapping';
import { useColPlainStyles, useColSize } from '../grid-mapping';
import {
  AlignContentSizes,
  AlignItemsSizes,
  AlignSelfSizes,
  Breakpoint,
  ColSizes
} from '../types';

interface Props extends ColSizes {
  className?: string;
  alignContent?: AlignContentSizes | AlignContentSizes[];
  alignItems?: AlignItemsSizes | AlignItemsSizes[];
  alignSelf?: AlignSelfSizes | AlignSelfSizes[];
}

export const Col: React.FC<Props> = ({
  className,
  alignContent,
  alignItems,
  alignSelf,
  children,
  sm,
  md,
  lg,
  xl
}) => {
  const small = useColSize(Breakpoint.sm, sm);
  const medium = useColSize(Breakpoint.md, md);
  const large = useColSize(Breakpoint.lg, lg);
  const xlarge = useColSize(Breakpoint.xl, xl);

  const plain = useColPlainStyles();
  const alignContentStyles = makeAlignStyles(alignContent);
  const alignItemsStyles = makeAlignStyles(alignItems);
  const alignSelfStyles = makeAlignStyles(alignSelf);

  const sizes = cn(small, medium, large, xlarge);

  return (
    <div
      className={cn(
        sizes || plain,
        alignContentStyles,
        alignItemsStyles,
        alignSelfStyles,
        className
      )}
    >
      {children}
    </div>
  );
};
