import React, { useState } from 'react';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { IconType } from 'components/Icon';

import styles from './ImageSlider.module.scss';

export interface SlideActionButton {
  buttonName: string;
  buttonTheme?: ButtonTheme;
  icon?: IconType;
  onButtonClick: (slideIndex: number) => void;
}

export interface Slide {
  slideIdentifier: number;
  slideHeader: JSX.Element;
  slideImage: string;
}

export interface ImageSliderProps {
  visible?: boolean;
  contentClassName?: string;
  imageDivClass?: string;
  openWithSlideIndex?: number;
  slides: Slide[];
  slideActions?: SlideActionButton[];
}

export const ImageSlider: React.FC<ImageSliderProps> = React.memo(
  ({
    visible = true,
    contentClassName,
    imageDivClass,
    slides,
    slideActions,
    openWithSlideIndex = 0
  }) => {
    const [currentSlideIndex, setcurrentSlideIndex] =
      useState(openWithSlideIndex);
    return (
      <>
        {slides.length > 0 && visible && (
          <div className={cn(styles.content, contentClassName)}>
            <div className={cn(styles.header)}>
              <div className={styles['header-content']}>
                {slides[currentSlideIndex].slideHeader}
              </div>
              <div className={styles['slider-arrows']}>
                <span>{`${currentSlideIndex + 1}/${slides.length}`}</span>
                <Button
                  icon={IconType.ChevronRight}
                  theme={ButtonTheme.SecondaryTransparent}
                  iconClassName={styles['previous-slide-icon']}
                  onClick={() => setcurrentSlideIndex(currentSlideIndex - 1)}
                  disabled={currentSlideIndex === 0}
                />
                <Button
                  icon={IconType.ChevronRight}
                  theme={ButtonTheme.SecondaryTransparent}
                  onClick={() => setcurrentSlideIndex(currentSlideIndex + 1)}
                  disabled={currentSlideIndex === slides.length - 1}
                />
              </div>
            </div>
            <div className={cn(styles['slide-image'], imageDivClass)}>
              <img
                src={`data:image/png;base64,${slides[currentSlideIndex].slideImage}`}
                alt={`slide-${currentSlideIndex}`}
              />
            </div>
            {slideActions && slideActions?.length > 0 && (
              <div className={styles['slide-actions']}>
                {slideActions.map((action) => {
                  return (
                    <Button
                      theme={action.buttonTheme}
                      title={action.buttonName}
                      icon={action.icon}
                      onClick={() =>
                        action.onButtonClick(
                          slides[currentSlideIndex].slideIdentifier
                        )
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);
