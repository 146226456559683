import React, { forwardRef, MutableRefObject } from 'react';
import cn from 'classnames';

import styles from './PropertiesTabs.module.scss';

export interface PropertiesTabElement {
  title: string;
  id: number | string;
  index: number;
  disabled?: boolean;
}

interface Props {
  className?: string;
  data: Omit<PropertiesTabElement, 'index'>[];
  tabsRefs?: MutableRefObject<Array<HTMLSpanElement | null>>;
  selectedTabId: PropertiesTabElement['id'];
  onSelect: (tab: PropertiesTabElement) => void;
}

export const PropertiesTabs = forwardRef<HTMLDivElement, Props>(
  ({ data, selectedTabId, onSelect, tabsRefs, className }, ref) => (
    <div
      className={cn(styles.container, className)}
      ref={ref}
      data-testid='properties-tabs-container'
    >
      {data.map(({ title, id, disabled }, index) => (
        <span
          key={id}
          className={cn(
            styles.tab,
            selectedTabId === id && styles.active,
            disabled && styles.disabled
          )}
          ref={(ref) => {
            if (tabsRefs) {
              tabsRefs.current[index] = ref;
            }
          }}
          onClick={() => onSelect({ id, title, index })}
        >
          {title}
        </span>
      ))}
    </div>
  )
);
