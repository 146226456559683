import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { TFunction } from 'i18next';

const makeFallback = (
  key: string,
  defaultValue?: string,
  debug: boolean = false
) => {
  const missingKey = debug ? `${key}*` : key;
  return defaultValue || missingKey;
};

export const useCustomTranslation = (): {
  t: TFunction;
  i18n: UseTranslationResponse[1];
  ready: boolean;
} => {
  const { t, i18n, ready } = useTranslation();

  const customT: TFunction = (key: string, defaultValue?: string) =>
    t(
      `${key}.displayValue`,
      makeFallback(key, defaultValue, i18n.options.debug)
    );

  return { t: customT, i18n, ready };
};
