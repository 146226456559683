import React from 'react';
import cn from 'classnames';

import styles from './TableFooter.module.scss';

interface Props {
  className?: string;
}

export const TableFooter: React.FC<Props> = ({ children, className }) => (
  <footer
    data-testid='table-footer'
    className={cn(styles['table-footer'], className)}
  >
    {children}
  </footer>
);
