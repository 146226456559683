import { IconType } from '../Icon';

export enum StatusIndicatorStatus {
  Error = 'error',
  NonRetryError = 'non-retry-error',
  Loading = 'loading',
  Saved = 'saved'
}

export const StatusIndicatorIcon = Object.freeze<
  Record<StatusIndicatorStatus, IconType>
>({
  [StatusIndicatorStatus.Error]: IconType.Error,
  [StatusIndicatorStatus.NonRetryError]: IconType.Error,
  [StatusIndicatorStatus.Saved]: IconType.Saved,
  [StatusIndicatorStatus.Loading]: IconType.Preloader
});
