import React, { useMemo } from 'react';
import { ReactTransformer } from 'components/HTMLParser';
import { useReportDataValue } from 'components/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

import styles from './HeadModel.module.scss';

const transformer = new ReactTransformer();

export class HeadModel extends BaseBlock<{}, string | number> {
  identifier = BlockIdentifier.HeadModel;

  Component: React.FC<{}> = () => {
    const value = useReportDataValue(this.accessor);
    const template = useMemo(() => {
      if (typeof value === 'string') {
        return transformer.sanitize(value);
      }

      return '';
    }, [value]);

    if (typeof value !== 'string') {
      return null;
    }

    return (
      <div className={styles.headmodel}>
        <div dangerouslySetInnerHTML={{ __html: template }} />
      </div>
    );
  };
}
