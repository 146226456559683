import React from 'react';
import { generateData } from 'components/Table/generateData';
import { range } from 'helpers/common';

import { Table, TableColumn, TableProps } from '../Table';
import { TableEmptyCell } from '../TableEmptyCell';
import { CellTheme } from '../TableEmptyCell/TableEmptyCell.enum';

interface Props
  extends Pick<
    TableProps<Record<string, void>>,
    'className' | 'bodyClassName' | 'footer'
  > {
  columnsAmount?: number;
  rowsAmount: number;
}

const emptyColumns = (
  amount: number = 8
): TableColumn<Record<string, void>>[] => {
  return range(amount).map((index) => ({
    id: String(index),
    order: 0,
    isVisible: true,
    accessor: `cell${index}`,
    Header: <TableEmptyCell theme={CellTheme.Primary} />,
    Cell: () => <TableEmptyCell theme={CellTheme.Secondary} />
  }));
};

const newEmptyCell = () => {
  const defaultCell = {};

  range(8).map((index) => (defaultCell[`cell${index}`] = index));

  return defaultCell;
};

export const TableLoading: React.FC<Props> = ({
  columnsAmount,
  rowsAmount,
  ...props
}) => {
  return (
    <Table
      {...props}
      disabled
      overflowed
      columns={emptyColumns(columnsAmount)}
      data={generateData<any>(newEmptyCell, rowsAmount)}
    />
  );
};
