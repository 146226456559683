import React from 'react';

import { BaseBlock } from './blocks/BaseBlock/BaseBlock';

interface Props {
  schema: BaseBlock;
}

export const SchemaRenderer: React.FC<Props> = ({ schema: Schema }) => {
  return (
    <Schema.Component {...Schema.metadata}>
      {Schema.items.map((item, index) => (
        <SchemaRenderer schema={item} key={item.identifier + index} />
      ))}
    </Schema.Component>
  );
};
