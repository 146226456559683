import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

import styles from './Float.module.scss';

interface MetaData {
  float?: 'right' | 'left';
  clear?: boolean;
  width?: CSSProperties['width'];
}

export class Float extends BaseBlock<MetaData> {
  identifier = BlockIdentifier.Float;

  Component: React.FC<MetaData> = ({ children, float, clear, width }) => {
    return (
      <div
        className={cn(float && styles[float], {
          [styles.clear]: clear
        })}
        style={{
          width
        }}
      >
        {children}
      </div>
    );
  };
}
