import React from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';
import { ActionBannerTheme } from './ActionBanner.enum';

import styles from './ActionBanner.module.scss';

interface Props {
  theme?: ActionBannerTheme;
  title: string;
  className?: string;
}

export const ActionBanner: React.FC<Props> = ({
  theme = ActionBannerTheme.Primary,
  title,
  className,
  children
}) => (
  <div className={cn(styles['action-banner'], styles[theme], className)}>
    <div className={styles['main-content']}>
      <Icon iconType={IconType.Info} className={styles.icon} />
      <p className={styles.title}>{title}</p>
    </div>
    {children}
  </div>
);
