import React, {
  ChangeEvent,
  FocusEvent,
  Fragment,
  useEffect,
  useRef
} from 'react';
import { useCallback } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import cn from 'classnames';
import memoizee from 'memoizee';

import { ErrorMessage } from '../ErrorMessage';
import { TextArea } from '../TextArea';
import { FormInputTheme } from './FormInput.enum';

import styles from './FormInput.module.scss';

const getMaskSymbols = memoizee((mask: InputMaskProps['mask']) => {
  if (typeof mask === 'string') {
    const symbols = mask
      .replaceAll('9', '')
      .replaceAll('a', '')
      .replaceAll('*', '');

    return symbols.split('');
  }

  return [];
});

interface Props extends Omit<InputMaskProps, 'mask'> {
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string | number;
  error?: string;
  className?: string;
  multiline?: boolean;
  isFocused?: boolean;
  onNavigationButton?: (key: string) => void;
  theme?: FormInputTheme;
  mask?: InputMaskProps['mask'];
  type?: 'text' | 'number';
}

export const FormInput = React.forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  Props
>(
  (
    {
      className,
      multiline,
      error,
      theme,
      placeholder,
      disabled,
      readOnly = false,
      type = 'text',
      isFocused,
      onNavigationButton,
      mask = '',
      maskChar = ' ',
      beforeMaskedValueChange,
      ...inputProps
    },
    ref
  ) => {
    const inputTheme = error ? FormInputTheme.Error : theme;
    const localInputRef = useRef<any>(null);

    const onBeforeMaskedValueChange = useCallback<
      Exclude<InputMaskProps['beforeMaskedValueChange'], undefined>
    >(
      (newValue, newState, userInput, maskOptions) => {
        const { value, selection } = beforeMaskedValueChange
          ? beforeMaskedValueChange(newValue, newState, userInput, maskOptions)
          : newValue;

        const maskSymbols = getMaskSymbols(maskOptions.mask);

        const plainValue = maskSymbols.reduce((acc, symbol) => {
          return acc.replaceAll(symbol, '');
        }, value);

        return { value: plainValue ? value : '', selection };
      },
      [beforeMaskedValueChange]
    );

    useEffect(() => {
      if (isFocused && localInputRef?.current?.getInputDOMNode) {
        localInputRef?.current?.getInputDOMNode()?.focus();
      }
    }, [isFocused]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        onNavigationButton &&
        isFocused &&
        (event.key === 'Tab' ||
          event.key === 'ArrowDown' ||
          event.key === 'ArrowUp')
      ) {
        event.preventDefault();
        localInputRef?.current?.getInputDOMNode()?.blur();

        onNavigationButton(event.key);
      }
    };

    return (
      <Fragment>
        {multiline ? (
          <TextArea
            {...inputProps}
            error={error}
            theme={inputTheme}
            disabled={disabled}
            readOnly={readOnly}
            className={className}
            aria-invalid={!!error}
            placeholder={readOnly ? '-' : placeholder}
            ref={ref as React.Ref<HTMLTextAreaElement>}
          />
        ) : (
          <InputMask
            {...inputProps}
            beforeMaskedValueChange={onBeforeMaskedValueChange}
            mask={mask}
            type={type}
            maskChar={maskChar}
            readOnly={readOnly}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            aria-invalid={!!error}
            placeholder={readOnly ? '-' : placeholder}
            className={cn(
              styles['form-input'],
              inputTheme && styles[inputTheme],
              className
            )}
            inputRef={ref as React.Ref<HTMLInputElement>}
            // @ts-ignore
            ref={localInputRef}
          />
        )}
        <ErrorMessage error={error} />
      </Fragment>
    );
  }
);
