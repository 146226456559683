import React, { ReactNode } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { Icon, IconType } from 'components/Icon';

import styles from './Modal.module.scss';

export interface ModalProps {
  title?: ReactNode;
  visible: boolean;
  contentHidden?: boolean;
  transparent?: boolean;
  icon?: IconType;
  handleVisible: () => void;
  className?: string;
  iconClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
}

export const Modal: React.FC<ModalProps> = React.memo(
  ({
    children,
    icon,
    title,
    visible,
    handleVisible,
    className,
    headerClassName,
    iconClassName,
    contentClassName,
    contentHidden,
    transparent
  }) => (
    <div
      className={cn(
        styles.overlay,
        { [styles.visible]: visible, [styles.transparent]: transparent },
        className
      )}
    >
      {visible && (
        <OutsideClickHandler
          onOutsideClick={() => !contentHidden && handleVisible()}
        >
          <div
            className={cn(styles.content, contentClassName, {
              [styles.hidden]: contentHidden
            })}
            data-testid='modal-content'
          >
            <div className={cn(styles.header, headerClassName)}>
              {icon && (
                <Icon
                  iconType={icon}
                  data-testid='modal-icon'
                  className={cn(styles['main-icon'], iconClassName)}
                />
              )}
              <span className={styles.title}>{title}</span>
              <Button
                className={styles['btn-close']}
                onClick={handleVisible}
                icon={IconType.Close}
                theme={ButtonTheme.SecondaryTransparent}
                iconClassName={styles.icon}
              />
            </div>
            {children}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
);
