import React, { FC } from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'components/Icon';
import {
  EventsTreeSubitem,
  EventsTreeSubitemTheme,
  EventsTreeSubitemType
} from 'components/ReportDataContainer/ReportDataContainer';

import styles from './ShoppingCart.module.scss';

interface Props {
  className?: string;
  nodes: EventsTreeSubitem[];
}

export const ShoppingCart: FC<Props> = ({ nodes, className }) => (
  <div className={cn(className, styles.container)}>
    {nodes.map(
      ({ text, theme = EventsTreeSubitemTheme.Primary, type }, index) => (
        <span
          className={styles[theme]}
          data-testid='shoppingCart-node'
          key={index}
        >
          {type === EventsTreeSubitemType.Screenshot ? (
            <>
              <Icon iconType={IconType.Eeg} />
              {text}
            </>
          ) : (
            <>{text}</>
          )}
        </span>
      )
    )}
  </div>
);
