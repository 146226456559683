export enum TooltipPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

export enum TooltipTheme {
  Primary = 'primary',
  Warning = 'warning'
}

export enum TooltipIds {
  DefaultPrimary = 'default-primary-tooltip',
  DefaultWarning = 'default-warning-tooltip'
}
