import React from 'react';
import cn from 'classnames';

import styles from './ErrorMessage.module.scss';

interface Props {
  error?: string;
  className?: string;
}

export const ErrorMessage: React.FC<Props> = ({ error, className }) => {
  if (!error) {
    return null;
  }

  return (
    <div className={cn(styles['error-message'], className)} role='alert'>
      {error}
    </div>
  );
};
