export const isValidUrl = (string: string): boolean => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

export const isNullish = (value: unknown): boolean => {
  if (typeof value === 'string') {
    return !value.split(' ').join('').length;
  }

  return value === undefined || value === null;
};

export const isHTML = (string: string): boolean =>
  /<\/?[a-z][\s\S]*>/i.test(string);
