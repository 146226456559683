import React from 'react';
import { Row as RowComponent } from 'components/Grid/Row';
import {
  AlignContentSizes,
  AlignItemsSizes,
  AlignSelfSizes,
  JustifySizes
} from 'components/Grid/types';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

interface Metadata {
  justify?: JustifySizes;
  alignContent?: AlignContentSizes | AlignContentSizes[];
  alignItems?: AlignItemsSizes | AlignItemsSizes[];
  alignSelf?: AlignSelfSizes | AlignSelfSizes[];
}
export class Row extends BaseBlock<Metadata> {
  identifier = BlockIdentifier.Row;

  Component: React.FC<Metadata> = ({
    children,
    justify,
    alignContent,
    alignItems,
    alignSelf
  }) => {
    return (
      <RowComponent
        gutters={false}
        justify={justify}
        alignContent={alignContent}
        alignItems={alignItems}
        alignSelf={alignSelf}
      >
        {children}
      </RowComponent>
    );
  };
}
