import React, { Fragment, ReactElement, useMemo } from 'react';
import {
  default as ReactSelect,
  Props as SelectProps,
  Theme
} from 'react-select';
import { StylesConfigFunction } from 'react-select/src/styles';
import cn from 'classnames';

import { ErrorMessage } from '../ErrorMessage';
import { FormInput } from '../FormInput';
import { SelectClearIndicator } from '../SelectClearIndicator';
import { SelectPosition, SelectTheme } from './Select.enum';

import styles from './Select.module.scss';

export type SelectOptionType = {
  label: string;
  value: string;
  code?: string;
  isMultiSelect?: boolean;
};

export type SelectRef<T> =
  | string
  | ((instance: ReactSelect<T, any> | null) => void)
  | React.RefObject<ReactSelect<any>>
  | null;

interface Props<T extends SelectOptionType> extends SelectProps<T> {
  error?: string;
  readOnly?: boolean;
  readOnlyValue?: string;
  className?: string;
  errorMessage?: string;
  menuMaxHeight?: number;
  position?: SelectPosition;
  selectTheme?: SelectTheme;
}

const reactSelectTheme =
  (error: string) =>
  (theme: Theme): Theme => {
    const customThemeColors: { [key: string]: string } = error
      ? {
          primary: styles.R500,
          primary50: styles.N100,
          neutral30: styles.R500
        }
      : {
          primary: styles.Pr500,
          primary25: styles.N100,
          primary50: styles.N100
        };

    return {
      ...theme,
      colors: {
        ...theme.colors,
        ...customThemeColors
      }
    };
  };

export const resolveStyleConfigFunction = (func?: StylesConfigFunction) => {
  if (func && typeof func === 'function') {
    return func;
  }

  return () => ({});
};

export function SelectInner<T extends SelectOptionType>(
  {
    error = '',
    className,
    components,
    errorMessage,
    readOnly,
    readOnlyValue = '-',
    position = SelectPosition.Bottom,
    selectTheme,
    styles: providedStyles,
    ...inputProps
  }: Props<T>,
  ref?: SelectRef<T>
) {
  const noOptionsMessage = useMemo(
    () => (errorMessage ? () => errorMessage : undefined),
    [errorMessage]
  );

  const theme = useMemo(() => reactSelectTheme(error), [error]);

  if (readOnly) {
    return <FormInput multiline readOnly value={readOnlyValue} />;
  }

  const customComponents = {
    ...components,
    ClearIndicator: SelectClearIndicator
  };

  const isBottomPosition = position === SelectPosition.Bottom;

  return (
    <Fragment>
      <ReactSelect
        {...inputProps}
        ref={ref}
        components={customComponents}
        noOptionsMessage={noOptionsMessage}
        classNamePrefix='outlined-select-inp'
        className={cn(
          className,
          'outlined-select-inp',
          selectTheme && `outlined-select-inp--${selectTheme}`
        )}
        theme={theme}
        styles={{
          option: (base, state) => ({
            ...base,
            fontSize: 14,
            padding: '4px 16px',
            color: styles.N1000,
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: !state.isFocused ? 'transparent' : styles.N100
          }),
          control: (base, props) => ({
            ...base,
            minHeight: 32,
            borderRadius: 4,
            boxShadow: 'none',
            backgroundColor: 'transparent',
            ...(error && { borderColor: styles.R500 }),
            ...resolveStyleConfigFunction(providedStyles?.control)(base, props)
          }),
          menuList: (base, props) => ({
            ...base,
            maxHeight: 700,
            maxWidth: 230,
            boxShadow: styles.S100,
            ...resolveStyleConfigFunction(providedStyles?.menuList)(base, props)
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 5
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: 5
          }),
          menu: (base, props) => {
            const errorMenu = errorMessage
              ? {
                  minHeight: 250,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              : {};
            return {
              ...base,
              width: 'auto',
              minWidth: 'calc(100% + 30px)',
              boxShadow: styles.S100,
              borderRadius: 6,
              top: isBottomPosition ? '100%' : 'auto',
              bottom: isBottomPosition ? 'auto' : '100%',
              ...errorMenu,
              ...resolveStyleConfigFunction(providedStyles?.menu)(base, props)
            };
          },
          valueContainer: (base) => ({
            ...base,
            padding: '0 0 0 12px',
            overflow: 'visible'
          }),
          singleValue: (base, props) => ({
            ...base,
            bottom: 4,
            transform: 'none',
            whiteSpace: 'nowrap',
            top: 'auto',
            maxWidth: 'calc(100% - 12px)',
            ...resolveStyleConfigFunction(providedStyles?.singleValue)(
              base,
              props
            )
          }),
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: 'pre-wrap'
          }),
          indicatorSeparator: (base) => ({
            ...base,
            visibility: inputProps.isClearable ? 'visible' : 'hidden'
          })
        }}
      />
      <ErrorMessage error={error} />
    </Fragment>
  );
}

export const Select = React.forwardRef(SelectInner) as <
  T extends SelectOptionType
>(
  p: Props<T> & {
    ref?: SelectRef<T>;
  }
) => ReactElement;
