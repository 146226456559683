import memoizee from 'memoizee';

import { TableColumn } from './Table';

const calcColumnWidth = memoizee((headerTitle: string) => {
  const [maxWidth, letterWidth, spaceWidth] = [400, 8, 48];

  return Math.min(maxWidth, headerTitle.length * letterWidth) + spaceWidth;
});

export const withColumnsWidth = <T extends object>(
  columns: Array<TableColumn<T>>
) =>
  columns.map((column) => {
    const header = column.Header;
    if (typeof header === 'string') {
      return {
        ...column,
        width: column.width || calcColumnWidth(header.toString())
      };
    }
    return column;
  });
