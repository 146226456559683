import React from 'react';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';

import styles from './SelectOption.module.scss';

interface Props {
  label: string;
  icon?: IconType;
  className?: string;
}

export const SelectOption: React.FC<Props> = ({ label, icon, className }) => (
  <div className={cn(styles['select-option'], className)}>
    <span className={styles.label}>{label}</span>
    {!!icon && (
      <Icon
        iconType={icon}
        className={styles.icon}
        data-testid='select-option-icon'
      />
    )}
  </div>
);
