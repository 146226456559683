import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReactSwitchProps } from 'react-switch';
import cn from 'classnames';

import { Icon, IconType } from '../Icon';
import { Switch } from '../Switch';

import styles from './SwitchRow.module.scss';

interface Props extends ReactSwitchProps {
  title: string;
  checked: boolean;
  pinned?: boolean;
  onPin?: () => void;
  isDragDisabled?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const SwitchRow: React.FC<Props> = ({
  title,
  onPin,
  pinned,
  checked,
  onChange,
  dragHandleProps = {}
}) => (
  <div className={styles['row-switch']}>
    <div className={styles['flex-aligned']}>
      <span
        {...dragHandleProps}
        className={styles['drag-container']}
        data-testid='dnd-button'
      >
        <Icon
          iconType={IconType.Dots}
          className={cn(
            styles['dots-icon'],
            !dragHandleProps && styles.disabled
          )}
        />
      </span>
      <span data-testid='row-title' className={styles.title}>
        {title}
      </span>
    </div>
    <div className={styles['flex-aligned']}>
      {onPin && (
        <button
          data-testid='pin-button'
          onClick={onPin}
          className={cn(
            styles['pin-button'],
            styles['flex-aligned'],
            pinned && styles.pinned
          )}
        >
          <Icon iconType={IconType.Pin} className={styles['pin-icon']} />
        </button>
      )}
      <Switch checked={checked} onChange={onChange} />
    </div>
  </div>
);
