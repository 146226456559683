import React, {
  createRef,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { ErrorTitles } from 'components/ErrorMessage';
import { ErrorTheme, GeneralError } from 'components/GeneralError';

import { IconType } from '../Icon';

import styles from './Tree.module.scss';

export interface TreeItem {
  label?: string | number;
  subLabel: string | number;
  id: string | number;
  hasChildren: boolean;
  children: TreeItem[];
  className?: string;
  isSelectable?: boolean;
  isMultiSelect?: boolean;
  expanded?: boolean;
  actionLabel?: string;
  parentId?: string | number | null;
  isHidden?: boolean;
}

interface Props {
  className?: string;
  actionLabelClassName?: string;
  noAnimation?: boolean;
  data: TreeItem[];
  isPlainList?: boolean;
  onClick: (item: TreeItem) => void;
  onItemStateChange?: (item: TreeItem, expanded: boolean) => void;
  highlightedInputMatchId?: string | number;
  highlightedItemId?: string | number;
  onActionLabelClick?: (item: TreeItem) => void;
  childIdsLoading?: Array<string | number>;
  iconType?: IconType.CaretRight | IconType.ChevronRight;
  rowIndentation?: number;
  expandBtnTabIndex?: number;
  actionLabelTabIndex?: number;
}

const transitionClassNames = {
  appear: styles.opened,
  enterActive: styles.opened,
  enterDone: styles.opened
};

const deepReducer = <T extends {}>(acc: T, item: TreeItem): T => {
  if (item.children?.length) {
    return {
      ...acc,
      [item.id]: item.expanded || false,
      ...item.children.reduce(deepReducer, {})
    };
  }
  return { ...acc, [item.id]: item.expanded || false };
};

export const Tree: FC<Props> = ({
  data,
  onClick,
  onItemStateChange,
  onActionLabelClick,
  isPlainList,
  className,
  actionLabelClassName,
  highlightedItemId,
  highlightedInputMatchId,
  childIdsLoading,
  iconType = IconType.CaretRight,
  rowIndentation = 32,
  expandBtnTabIndex = -1,
  actionLabelTabIndex = -1
}) => {
  const highlightedId = highlightedItemId || highlightedInputMatchId;

  const [treeState, setTreeState] = useState(
    data.reduce<Record<string | number, boolean>>(deepReducer, {})
  );

  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    setTreeState(
      data.reduce<Record<string | number, boolean>>(deepReducer, {})
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlainList]);

  useEffect(() => {
    setHasScrolled(false);
  }, [highlightedItemId]);

  const toggleTreeItem = useCallback(
    (id: string | number, expanded: boolean) => {
      setTreeState({
        ...treeState,
        [id]: expanded
      });
    },
    [treeState]
  );

  const scrollToHighlightedElement = useCallback(() => {
    const target = document?.querySelector<HTMLDivElement>(
      `[data-id='${highlightedItemId}']`
    );
    const container = ref.current;

    if (target && container) {
      const targetOffset = target.offsetTop + target.clientHeight / 2;
      const containerHeight = container.parentElement!.clientHeight;
      try {
        let scrollHeight = 0;
        if (targetOffset > containerHeight / 2) {
          scrollHeight = targetOffset - containerHeight / 2;
        }
        container.parentElement!.scrollTop = scrollHeight;
        setHasScrolled(true);
      } catch (e) {}
    }
  }, [highlightedItemId, ref]);

  const renderTreeGroup = (
    isChildrenLoading: boolean,
    data: TreeItem[],
    expanded: boolean,
    treeDepth: number = 1
  ) => {
    const firstRowIndentation = 0;
    const isLowestDepth = treeDepth === 1;
    const marginSize = isLowestDepth
      ? firstRowIndentation
      : (treeDepth - 1) * rowIndentation;

    const toggleTree = (item: TreeItem, expanded: boolean) => {
      onItemStateChange && onItemStateChange(item, expanded);
      toggleTreeItem(item.id, expanded);
    };

    const isErrorVisible = !data.length && !isChildrenLoading && expanded;

    return (
      <CSSTransition
        in={expanded}
        appear={expanded}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames={transitionClassNames}
        onEntered={!hasScrolled ? scrollToHighlightedElement : undefined}
      >
        <div
          className={cn(
            styles.tree,
            isLowestDepth && styles['no-animation'],
            isLowestDepth && expanded && className
          )}
        >
          <div ref={isLowestDepth ? ref : undefined} data-testid='tree'>
            {isErrorVisible ? (
              <GeneralError
                theme={ErrorTheme.Secondary}
                title={ErrorTitles.Load}
                style={{ paddingLeft: `${marginSize}px` }}
              />
            ) : (
              data.map((item) => {
                const {
                  id,
                  label,
                  subLabel,
                  children,
                  hasChildren,
                  className: labelClassName
                } = item;
                const expanded = treeState[id];

                const nextChildrenLoading = !!childIdsLoading?.find(
                  (id) => id === item.id
                );

                if (!isPlainList && item.isHidden) {
                  return (
                    <Fragment key={id}>
                      {renderTreeGroup(
                        nextChildrenLoading,
                        children,
                        true,
                        treeDepth
                      )}
                    </Fragment>
                  );
                } else if (isPlainList && item.isHidden) {
                  return null;
                }

                return (
                  <Fragment key={id}>
                    <div
                      data-testid={`row-${treeDepth}`}
                      data-id={id}
                      className={cn(styles['tree-row'], {
                        [styles.highlighted]:
                          highlightedId && id === highlightedId,
                        [styles['not-selectable']]: !item.isSelectable
                      })}
                      onClick={() => {
                        item.isSelectable && onClick(item);
                      }}
                    >
                      <Button
                        data-testid={`expander-${treeDepth}`}
                        icon={iconType}
                        theme={ButtonTheme.SecondaryTransparent}
                        style={{ marginLeft: `${marginSize}px` }}
                        className={cn(styles['icon-button'], {
                          [styles.hidden]: !hasChildren,
                          [styles.empty]: isPlainList || !hasChildren
                        })}
                        iconClassName={cn(styles.icon, {
                          [styles.expanded]: expanded,
                          [styles.hidden]: !hasChildren
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          hasChildren && toggleTree(item, !expanded);
                        }}
                        tabIndex={expandBtnTabIndex}
                      />
                      <div className={styles.labels}>
                        <div className={styles['labels-container']}>
                          {label && (
                            <span
                              className={cn(styles.label, {
                                [styles['not-selectable']]: !item.isSelectable
                              })}
                              data-testid={'label'}
                            >
                              {label}
                            </span>
                          )}
                          <span
                            className={cn(styles['sub-label'], labelClassName, {
                              [styles['not-selectable']]: !item.isSelectable
                            })}
                            data-testid={'sub-label'}
                          >
                            {subLabel}
                          </span>
                        </div>

                        {item.actionLabel && onActionLabelClick && isPlainList && (
                          <Button
                            data-testid={'action-label'}
                            className={cn(
                              styles['action-label'],
                              actionLabelClassName
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              onActionLabelClick(item);
                            }}
                            title={item.actionLabel}
                            theme={ButtonTheme.SecondaryTransparent}
                            tabIndex={actionLabelTabIndex}
                          />
                        )}
                      </div>
                    </div>
                    {!isPlainList &&
                      renderTreeGroup(
                        nextChildrenLoading,
                        children,
                        expanded,
                        treeDepth + 1
                      )}
                  </Fragment>
                );
              })
            )}
          </div>
        </div>
      </CSSTransition>
    );
  };

  return renderTreeGroup(false, data, true);
};
